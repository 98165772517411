import React, { useState } from "react";
import { set } from "react-hook-form";
//   import validation from "./LoginValidation";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";




export const Login  = () => { 

    const[email ,setEmail] = useState('')
    const [password , setPassword] = useState('')
    
    
    const handleEmail = (e) =>{
       setEmail(e.target.value)
    }

    const handlePassword = (e) =>{
         setPassword(e.target.value)
    }

    const handleApi = () =>{
        console.log({email , password})
        axios.post('https://reqres.in/api/login' , {
            email: email,
            password: password
        }).then(result =>{
            console.log(result)
        })
        .catch(error => {
            console.log(error)
        })
    } 

    return(
        <div className="d-flex justify-content-center align-items-center vh-100" >
              <div className="p-3 rounded  login-page">
                <h2 className="text-h">sign-in</h2>
                 <form   className="page">
                    <div className="mb-3">
                        <label htmlFor="email">Email</label>
                        <input type="email" placeholder="Enter Email" name="email" className="form-control rounded-0" onChange={handleEmail}
                        ></input>
                                                  </div>

                    <div className="mb-3">
                        <label htmlFor="password">Password</label>
                        <input type="email" placeholder="Enter password"  name="password" className="form-control rounded-0" onChange={handlePassword}
                        ></input>
                      
                    </div>
                    <Link to="/">
                 <button type="submit" className="btn btn-success w-100 rounded-0"  onClick={handleApi} style={{backgroundColor: '#262380'}}> <strong>Login</strong></button></Link>

                         <p> </p> 

                  {/* <Link to="/sign1" className="btn btn-default border w-100 bg-light">create account</Link>  */}
                 
                 
                            
                 </form>
              </div>
        </div>
    )

}





