
import React from 'react';


 export const Hero = () =>
{
    return(
        <div  id='hero'  className='hero'>
           <div className="container " style={{marginLeft:0}}>
            <div className="slider">
            <img src="\img\slider1.jpg"></img>
           </div>
           </div>
           </div>
    
    )
}