import React from "react";
  import '../VPS/Common1.css';
import { Link } from "react-router-dom";
  


import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Windowsvirtual = () =>{

  const handleTabClick = (paymentType) => {
    // Implement the logic for handling tab clicks here
    console.log(`Selected payment type: ${paymentType}`);
  };

    return(
    <div>
        <div className="bg-heading">
      <div className="container bg">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <h1 className="breadcrumb-heading">Windows Virtual Private Server</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="bread-crumb">
              <a href="/">Home</a> / <a href="virtual-private-server" className="active" title="Linux Virtual Private Server">Windows VPS</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="hosting-block1 parallax-section">
      <div className="container hb1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <img src="\img\hostingblock1.png" alt="Cheap VPS hosting in India" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
              <div className="domain-title">
                <h2>Windows Virtual Private Server</h2>
              </div>
              <p className="font18">What else you require if you have your own Server</p>
              <ul className="h-list">
                <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Unlimited Bandwidth with Massive Storage
                </li>
                <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Linux and Windows Platforms
                </li>
                <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Java Hosting solution with Linux Server
                </li>
                <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Effective Cost (15% OFF - Use Code: ALLVPS15)
                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Complete Administrator access
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div className="hosting-block2  parallax-section">
        <div className="container hb2">
           <div className="row text-center">
             <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
               <h2 className="section-heading">CHOOSE YOUR PERFECT PLAN</h2>
                <p className="section-subheading text-muted mb-4">Servers with high performance, high reliability, in any imaginable variety and size</p>
             </div>

             <div className="col-12 col-sm-12 col-md-12col-lg-14 col-xl-12 mb-1 col-table wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{visibility:'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown'}}>
                <div className="tab-btn">
                <span>
                     <Link to="/hs">
                           <a href="high-speed-ssd-vps" className="ch-btn">
                                <i className="fab fa-linux" aria-hidden="true"></i> &nbsp;LINUX NVMe SSD VPS
                           </a>
                          </Link>
                      </span> 
                      <span>
                               <Link to="/lvps">
                            <a  className="ch-btn">
                             <i className="fab fa-linux" aria-hidden="true"></i> &nbsp;LINUX VPS HOSTING
                           </a>
                           </Link>
                      </span>
                     <span>
                     <Link to="/wvps">
                          <a href="windows-virtual-private-server" className="ch-btn  active-btn">
                            <i className="fab fa-windows" aria-hidden="true"></i> &nbsp;WINDOWS VPS HOSTING
                         </a>
                         </Link>
                      </span>
               </div>

               <div className="row">
                 <div className="st-wrapper table-responsive">

                 <table className="compare-table">
                     <thead>
                        <tr className="compare-heading">
                           <th colSpan="4">Windows VPS( OS - Windows Server 2019 Standard)</th>
                        </tr>
                         <tr className="title-compare-table">
                            <td>PLAN</td>
                            <td>Specification</td>
                            <td>PRICE</td>
                           <td></td>
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - HW244</span>
                                 <i className="fab fa-windows fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>2 CORE, 4GB RAM, 40GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>35.37</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$30.75/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>35.37</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$32.15 /mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>35.37</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$33.68 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">$35.37/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>

                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - MW243</span>
                                 <i className="fab fa-windows fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>	2 CORE, 4GB RAM, 300GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>38.54</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$33.51/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>38.54</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$35.04/mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>38.54</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$36.71 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">38.54/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - HW488</span>
                                 <i className="fab fa-windows fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>4 CORE, 8GB RAM, 80GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>50.50</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$43.91/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>50.50</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$45.91 /mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>50.50</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$48.09 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">$50.50/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - MW485</span>
                                 <i className="fab fa-windows    fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>4 CORE, 8GB RAM, 500GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>55.62</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$48.37/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>55.62</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$50.56 /mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>55.62</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$52.97 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">$55.62/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - HW6BA</span>
                                 <i className="fab fa-windows fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>6 CORE, 12GB RAM, 100GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>65.38</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$56.85/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>65.38</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$59.44 /mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>65.38</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$62.26 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">$65.38/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">WINDOWS VPS - MW6B7</span>
                                 <i className="fab fa-windows fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>6 CORE, 12GB RAM, 700GB DISK SPACE, UNLIMITED BANDWIDTH</td>
                          <td>
                            <div className="tab-content p-0">
                               <div className="tab-pane container active" id="annually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>72.70</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="annually1">$63.22/mo</span><br />
                                </div>
                               <div className="tab-pane container fade" id="semiannually_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>72.70</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="semiannually1">$66.09 /mo</span><br />
                               </div>
                              <div className="tab-pane container fade" id="quarterly_1">
                                    <span className="currency">$</span>
                                    <span className="price1"><del>72.70</del></span>/
                                    <span className="unit">mo<br /></span>
                                    <span className="host-price" id="quarterly1">$69.24 /mo</span><br />
                              </div>
                              <div className="tab-pane container fade" id="monthly_1">
                                <span className="host-price" id="monthly1">$72.70/mo</span><br />
                               </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                     <ul className="nav nav-pills">
                                         <li className="nav-item monthtab">
                                     <a className="nav-link active" onClick={() => handleTabClick('annually')} href="#annually_1">
                                      12<span className="month">M</span>
                                    </a>
                                     </li>
                                         <li className="nav-item monthtab">
                                       <a
                                          className="nav-link"
                                           onClick={() => handleTabClick('semiannually')}
                                                  href="#semiannually_1"
                                        >
                                           6<span className="month">M</span>
                                        </a>
                                       </li>
                                       <li className="nav-item monthtab">
                                        <a
                                            className="nav-link"
                                             onClick={() => handleTabClick('quarterly')}
                                             href="#quarterly_1"
                                         >
                                            3<span className="month">M</span>
                                         </a>
                                         </li>
                                           <li className="nav-item monthtab">
                                             <a
                                                 className="nav-link"
                                                 onClick={() => handleTabClick('monthly')}
                                                  href="#monthly_1"
                                              >
                                           1<span className="month">M</span>
                                             </a>
                                            </li>
                                         </ul>
                            </div>

                            </td>

                            <td>
                            <div id="sls_btn">
                                   <a href="" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>


                        

                       

                      </tbody>
                                    
                    </table>

                 </div>

               </div>
             </div>

           </div>
        </div>

     </div>


               <div className="live-demo">
                   <div className="container ld">
                        
                        <div className="c-white text-center wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
                                <div className="section-heading mb-2">Contact for more Information</div>
                                     <p className="section-subheading">Ask more, we are happy to clarify all your doubts</p>
                               </div>

                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                                    <form id="form" method="post">    
                                 <div className="row_ld">
                                   <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 email-ld">
                                       <label className="email">Email</label>
                                       <input className="input-box" id=" email" name="email" placeholder="Email Address" type="text" ></input>
                                   </div>

                                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <label className="password">Password</label>
                                        <input className="input-box"  id="password" name="password" placeholder="Password" type="text"></input>
                                  </div>

                    
                                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                     <label className="Description">Description</label>
                                     <textarea className="input-box" placeholder="Define VPS Specification" name="desc" id="desc" required></textarea>
                                  </div>
                       

                                   <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <button className="searchsubmit" name="btnSubmit" id="btnSubmit">
                                          {/* <span className="fa fa-cloud-upload"></span> */}
                                          Send
                                      </button>   
                                   </div>  
                                 </div>
                                    </form>
                                   </div>  
                               </div>    


                       
                   </div>
               </div>


               <div className="hosting-features mb-3">

                   <div className="container hf">

                      <div className="row justify-content-center mb-5">
                          <div className="col-md-8 text-center heading-section">
                             <h2 className="">
                              <span>Features of </span>
                                 Windows VPS
                             </h2>
                          </div>
                       </div>


                 <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa fa-cubes fa-4x c-text-green mb-2"></i>
                                       <h4>Ultra performance</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                       VPS Gives the highest performance for computational programming, 
                                        huge database access, Dynamic web applications, demon process and backup/restore processes.
                                    
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fab fa-snapchat fa-4x c-text-green mb-2"></i>
                                       <h4>Take a Snapshots</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     The VPS is based on KVM and you can take a snapshot to restore easily.
                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-lock fa-4x c-text-green mb-2"></i>
                                       <h4>Discounted SSL and Dedicated IP Address</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     The SSL certificate and dedicated IP address are most useful to increase security, reduce spamming and improve SEO
                                    
                                   </div>

                               </div>
                          </div>
                 </div>                       

                 <div className="rows">                    
                              <div class="columns">
                                   <div class="cards">
                                     <div className="features mb-5">
                                          <i className="fa fa-cog fa-4x c-text-green mb-2"></i>
                                          <h4>Easy to use Control Panels</h4>
                                     </div>

                                       <div className="pt-3 p-txt">
                                           The complete system administration is very tough and not possible by a single skilled person, Take an advantage of highly popular panels with a legal license.                                                
                                       </div>

                               </div>
                              </div>


                               <div class="columns">
                                  <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fab fa-windows fa-4x c-text-green mb-2"></i>
                                       <h4>Letest Operating Systems</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Get Letest Operating System - Windows Server 2019 Standard, Also ask for older version of windows server                                               </div>

                                  </div>
                               </div>

                               
                               <div class="columns">
                                 <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-eye fa-4x c-text-green mb-2"></i>
                                       <h4>Monitoring as a guardian</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Monitoring features are available and you will be notified suddenly, you can monitor all your services, CPU utilization, memory consumption, and security.
                                   </div>
                               </div>
                               </div>
                   </div>


                   </div>

               </div>


               <div className="live-demoo c-white">
                  <div className="container ld">
                              <div className="col-sm-12 text-center mb-4 "> 
                                     <div className="domain-title">
                                        <div className="dt1">
                                          <h2>Advanced Features of Windows Virtual Private Server</h2><br />
                                          </div>
                                     </div>
                               </div> 


                              <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-check-square fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Ready Deployable environment with easy to use control panels</h4>
                                               <p>Save your valuable time using cPanel and Plesk, it provides a ready-to-code environment,
                                                   1-click installation and upgrade of CMS like WordPress, Joomla, Magento, etc.
                                                    Do easy administration using cPanel or Plesk from a single window.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-tasks fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Be a hosting provider</h4>
                                               <p>cPanel/WHM and Plesk panel provides central control with full administration rights over customers and resellers, create plans and packages and sell your customers or resellers from root access, get benefit by adding more plugins, securities, and timely notifications
                                                
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-fast-forward fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Unlimited Traffic with high speed</h4>
                                               <p>All plans are available with unlimited bandwidth and minimum 100Mbps speed, there is no more cost for extra usage. Dedicated lines with 1Gbps are available for ultra-high speed.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-cogs fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Fully Managed solutions</h4>
                                               <p>We provide fully managed solutions as per the applications those you require. Will suggest the best suitable plan, set up for you, make it deployable and take care of it in terms of snapshots, backups, and monitoring. We set firewalls rules and os hardening.
                                                </p>
                                     </div>
                               </div>


                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-graduation-cap fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Go with our consultation</h4>
                                      <p> Fully Managed servers with 24/7 dedicated support on your choice of application, we make full secure, safe and high-performance bunch using our checklist which includes dedicated IP per domain, SSL security, firewall setup, auto backup, increase performance by clearing dump files and killing processes, file and directory permissions, snapshots and monitoring.</p>
                                     </div>
                               </div>



                  </div>
               </div>
                        

                        <div className="faq">
                            <div className="container fq">
                            <div className="section-heading text-center mb-5 mb-2">Frequently Asked Questions</div>
                                  
                                      
                            <Collapse defaultActiveKey={['1']}>
          <Panel header="What is a VPS" key="1" >
            <p> 
                  A Linux Virtual Private Server is an isolated server environment created after partitioning a physical server into multiple virtual servers with each server having its own share of dedicated resources like CPU, memory and disk space. Each VPS is absolutely ‘private’ from the other servers sharing the same host node and each one functions just like any other regular server with root SSH access. With multiple Linux Virtual Private Servers functioning from one physical server, the operating costs are significantly
                 reduced which in turn makes VPS hosting an affordable alternative to having your own dedicated server
           </p>
           
          </Panel>
          <Panel header="What are the upsides of VPS Hosting?" key="2">
            <p>The three primary points of interest that accompany VPS Hosting are: Complete Isolation: Your VPS bundle and its applications stay unaffected independent of what another clients does on the server
                 Root Access: This enables you to introduce any perfect utilization of your decision
                 Ensured Resources: Irrespective of any circumstance, the CPU, RAM, HDD and data transmission that have been designated to your bundle will consistently be accessible for your applications.</p>
          </Panel>
          <Panel header="What Virtualization software do you use?" key="3">
            <p>We use Kernel-based Virtual Machine (KVM).</p>


          </Panel>
          <Panel header="Would i be able to redesign between VPS plans?" key="4">
            <p>Truly. Our VPS servers are versatile. This implies you can update your current arrangement whenever and immediately. Be that as it may, we don't bolster plan downsize.</p>

          </Panel>
          <Panel header="Would i be able to introduce any sort of programming on my VPS Package? " key="5">
            <p>Since you have full root get to, you can introduce any product that is perfect on your VPS bundle. Notwithstanding, any product that disregards our Acceptable Usage Policy won't be permitted.</p>


          </Panel>
          <Panel header="Can I install control panels on my VPS package?" key="6">
            <p>Yes. We offer cPanel & Plesk as the hosting panels that you can use on your servers. cPanel & Plesk are auto-installed, if they are added during the server purchase.</p>
          </Panel>

          <Panel header="Do you give Graphic User Interface (GUI) for your Linux VPS? " key="7">
            <p>Our Linux VPS doesn't accompany GUI as a matter of course, yet we can introduce it for you and empower the VNC get to in the event that you present a help demand. You can likewise introduce GUI without anyone else through SSH.H.</p>
          </Panel>

          <Panel header="How long will it take for my VPS to be setup?" key="8">
            <p>When you pursue the ideal VPS facilitating bundle, the arrangement procedure will be attempted immediately by our group. You can begin utilizing your virtual server immediately after accepting the server and control board logins.</p>
          </Panel>

          <Panel header="Do you provide any server management or backups?" key="9">
            <p>We don't have any backup solution right now. However, we emphatically prescribe that you keep up a remote reinforcement through cPanel or Plesk to maintain a strategic distance from any issues during a disastrous episode.</p>
          </Panel>

          <Panel header="How do you do cancellations?" key="10">
            <p>Servers are dropped toward the finish of each charging period or promptly relying upon what the clients wants. Client is required to make a dropping solicitation at any rate 72 hours before their recharging date to guarantee it very well may be prepared in time. After a server is dropped all client information is erased and you won't have the option to recoup it on the off chance that you choose to reactivate later on.</p>
          </Panel>
        </Collapse>
        


                            </div>
                        </div>
   

    </div>
    )
}