import React from "react";


export const Feature=()=>{
    return(
        <div className="features">
            <div className="container  fs">

                <div className="text-center mb-5 mt-3">
                  <div className="section-heading text-uppercase"  style={{ marginBottom:50}}>
                  Featured Recommendations
                  </div>
                </div>
         <div className="grid-container">
            <div className="grid-row">
                   <div className="item">
                    <div className="prod-plan">
                         <a className="hyp-link" href="#"> 
                            <div className="prod-img  d-flex align-items-center justify-content-center">
                                <i className="fab fa-linux fa-3x"></i>
                            </div>
                      <div className="prod-data">
                <h4>Linux VPS</h4>
                <p>"Starting at"
                    <span className="price"><i className="fab fa-dollar"></i>"$16.48"</span>"/mo"
                </p>
                <p>15% off, Promo Code: ALLVPS15</p>
                      </div>
                       </a> 
                    </div>
                  </div>

               <div className="item">
                  <div className="prod-plan">
                         <a className="hyp-link" href="#"> 
                              <div className="prod-img  d-flex align-items-center justify-content-center">
                                <i className="fa fa-server fa-3x"></i>
                              </div>
                       <div className="prod-data">
                            <h4>Linux Shared Hosting</h4>
                              <p>"Starting at"
                                <span className="price"><i className="fab fa-dollar"></i>"$20.10"</span>"/yr"
                              </p>
                                <p>20% off, Promo code:ALLSH20</p>
                         </div>
                          </a> 
                         </div>
                      </div>

                
                   {/* <div className="item">
                   <div className="prod-plan">
                     
                            <div className="prod-img  d-flex align-items-center justify-content-center">
                                <i className="fa fa-coffee fa-3x"></i>
                            </div>
                    <div className="prod-data">
                <h4>Java Shared Hosting</h4>
                <p>"Starts at"
                    <span className="price"><i className="fab fa-dollar"></i>"$28.16"</span>"/yr"
                </p>
                <p>Dedicated Tomcat with cPanel</p>
                    </div>
                   
                    </div>
                  </div> */}
            </div>
      
             {/* <div className="grid-row">
                  

      <div className="item">

<div className="prod-plan">
        <a className="hyp-link" href="wvps">
           <div className="prod-img  d-flex align-items-center justify-content-center">
               <i className="fab fa-windows fa-3x"></i>
           </div>
<div className="prod-data">
<h4>Windows VPS</h4>
<p>"Starting at"
   <span className="price"><i className="fab fa-dollar"></i>"$38.88"</span>"/mo"
</p>
<p>15% off, Promo Code: ALLVPS15</p>
</div>
</a> 
</div>
     </div>
                            <div className="item item_1">
                            <div className="prod-plan">
                             <a className="hyp-link" href="/wsh">

                              <div className="prod-img  d-flex align-items-center justify-content-center">
                                <i className="fa fa-laptop fa-3x"></i>
                              </div>
                       
                         <div className="prod-data">
                            <h4>Windows Shared Hosting</h4>
                              <p>"Starting at"
                                <span className="price"><i className="fab fa-dollar"></i>"$24.12"</span>"/yr"
                              </p>
                                <p>20% off, Promo code:ALLSH20</p>
                         </div>
                           </a> 
                           </div>                   
                      </div>
                  <div className="item">
                   <div className="prod-plan">
                      
                              <div className="prod-img  d-flex align-items-center justify-content-center">
                                <i className="fa fa-globe fa-3x"></i>
                              </div>  
                         <div className="prod-data">
                            <h4>Register Domain</h4>
                              <p>"Starts at"
                                <span className="price"><i className="fab fa-dollar"></i>"$0.94"</span>"/yr"
                              </p>
                                <p>Get More Offers</p>
                         </div>
                      
                         </div>
                   </div> 
             </div> */}
         </div>



           
            </div>


            </div>
            
        
    )
}