import React from "react";


export  const Highspeed =() =>{
    return(
        <div className="m-intro-img m-intro  high-speed">
            <div className="overlay"></div>

              <div className="container hs">
                <div className="row-high-speed">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 row-speed">
                        <h2> Lightning-fast NVMe SSD VPS</h2>
                           <ul className="h-list mb-0">
                           <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms">
                             <i className="fa fa-chevron-circle-right"></i>  Combining NVMe SSDs and Intel Xeon Processors
                            </li>
                            <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms">
                                   <i className="fa fa-chevron-circle-right"></i> Boundless Traffic with Lightning-Fast 1Gbps Network



                             </li>
                             <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms">
                                     <i className="fa fa-chevron-circle-right"></i>KVM-Based Virtual Machine Deployment



                             </li>
                              <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms">
                                     <i className="fa fa-chevron-circle-right"></i>  Smooth and Simple Upgrades
                               </li>
                                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms">
                                     <i className="fa fa-chevron-circle-right"></i> No-Cost Server Customization Help
                                   </li>
                              <li className="wow fadeInDown" data-wow-duration="1100ms" data-wow-delay="1100ms">
                                    <i className="fa fa-chevron-circle-right"></i> Your Server Comes Pre-Configured with cPanel, Plesk, or CWP
                               </li>
                           </ul>

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
                         <img src="/img/nvme-vps-accelerate.png" alt="cheap virtual server hosting linux" />
                    </div>
                </div>

              </div>

            

         </div>
    )
}