import React from "react";
export const  Services = ()=> {
    return(
        <div className="services">
            <div className="container ss">
                <div className="row">
                    <div className=" text-center heading-section">
                        <h2 className="display-heading inner"  style={{color:"white"}}>SERVICES</h2>
                    </div>
                </div>


             <div className="grid-containers">
                <div className="grid-rows">
                   <div className="items">
                    <div className="inner">
                        <i className="fa fa-laptop circle"></i>
                        <div className="text">
                            <h6>VPS Hosting</h6>
                            Your server, your control: Unprecedented privacy, reliability, and scalability for extraordinary results.
                        </div>
                    </div>
                   </div>
                    <div className="items">
                        <div className="inner">     
                        <i className="fa fa-lock cicle"></i> 
                        
                          <div className="text">                   
                            <h6>SSL Certificate</h6>
                            Every web application requires an SSL Certificate as a foundational element to enhance security and establish trust.

                              </div>
                              </div>  

                    </div>
                </div>
                 <div className="grid-rows">
                    <div className="items">
                        <div className="inner">
                            <i className="fa fa-anchor"></i>
                            <div className="text">
                                <h6>Single Website Hosting</h6>
                                The professional's choice is to host a single website on their independent cPanel/Plesk, guaranteeing ultimate control and authority.                            </div>
                        </div>
                    </div>
                     <div className="items item_1">
                     <div className="inner">
                        <i className="fa fa-th-list circle"></i>
                            <div className="text text_1">
                                 <h6>Shared/Business Hosting</h6>
                                 Simplify website management for developers with an efficient and cost-effective solution that unifies all sites under one control panel.                            </div>
                        </div>

                     </div>
                </div>
                <div className="grid-rows_new ">
                    <div className="items item_1">

                    <div className="inner">
                        <i className="fa fa-globe circle"></i>
                            <div className="text">
                               <h6>Domain Registraion / Transfer</h6>
                               The domain name is the central hub of your business, capturing and guiding all nearby traffic                            </div>
                        </div>
                    </div>
                    {/* <div className="items item_1">
                    <div className="inner">
                    <i className="fa fa-server circle"></i>
                            <div className="text">
                                <h6>  Dedicated Server</h6>
                                AlgoOptimize is your partner in acquiring the latest and most advanced metal servers, all built to your specifications.
                            </div>
                        </div>
                    </div> */}
                </div> 
               </div>

               
                 

            </div>
        </div>
    )

}