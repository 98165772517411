import React ,{useState} from "react";
import './SearchBar.css';




const SearchBar = () => {

   
  const [domain, setDomain] = useState('');
  const domainToURLMap = {
    vps: '/vps',
    lvps: '/lvps',
    livps: '/livps',
    // Add more mappings as needed
  };

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Normalize the domain input (e.g., to lowercase)
    const normalizedDomain = domain.toLowerCase();

    // Check if there's a matching URL in the map
    if (domainToURLMap[normalizedDomain]) {
      // Redirect to the corresponding URL
      window.location.href = domainToURLMap[normalizedDomain];
    } else {
      // Handle the case where the domain is not found in the map
      alert('Domain not found');
    }
  };



  

  return (
    <div className="searchbar">

      <section className="domain_search_home">
        <div className="container">
          <div className="row-search-bar">

          {/* <div className="col-md-5">
              <h1 className="title">Reserve your domain now.</h1>
              <form
                id="domain-searchform"
               
              >
                <input type="hidden" name="direct" value="true" />
                <input
                  className="input-text"
                  name="domain"
                  id="domain"
                  placeholder="yourwebsite.com"
                  type="search"
                />
                <input
                  id="searchsubmit"
                  className="searchsubmit"
                  value="Search"
                  type="submit"
                />
              </form>
              <div className="clearfix"></div>
            </div> */}
        
        <div className="col-md-5">
      <h1 className="title">Reserve your domain now.</h1>
      <form id="domain-searchform"  onSubmit={handleSubmit} >
        <input type="hidden" name="direct" value="true" />
        <input
          className="input-text"
          name="domain"
          id="domain"
          placeholder="yourwebsite.com"
          type="search"
          value={domain}
          onChange={handleDomainChange}
          
        />
        <input
          id="searchsubmit"
          className="searchsubmit"
          value="Search"
          type="submit"
        />
      </form>
      <div className="clearfix"></div>
        </div>



           
            <div className="col-md-7 box">
              <div id="myCarousel" className="carousel slide" data-ride="carousel">

                {/* <div className="control-box pager">
                  <a className="carousel-control carousel-control-prev" href="#myCarousel" data-slide="prev">
                    <i className="fa fa-angle-left"></i>
                  </a>
                  <a className="carousel-control carousel-control-next" href="#myCarousel" data-slide="next">
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div> */}


                <div className="carousel-inner">

                  {/* <div className="carousel-item">
                    <div className="row">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img src="img/dotcom.png" className="img-responsive domain-img" alt="free domain name" />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency1">$</span>
                                  <span id="price1">11.59</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/dotin.png"
                              className="img-responsive domain-img"
                              alt="best virtual server hosting"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency4">$</span>
                                  <span id="price4">8.17</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/dotnet.png"
                              className="img-responsive domain-img"
                              alt="virtual server hosting uk"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency2">$</span>
                                  <span id="price2">12.93</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/dotorg.png"
                              className="img-responsive domain-img"
                              alt="linux virtual server hosting"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency3">$</span>
                                  <span id="price3">14.76</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div> */}


                  {/* <div className="carousel-item active">
                    <div className="row">

                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/dotxyz.png"
                              className="img-responsive domain-img"
                              alt="best virtual private server hosting india"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency6">$</span>
                                  <span id="price6">2.53</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>


                      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="col-item">
                          <div class="photo">
                            <img src="img/dotinfo.png" class="img-responsive domain-img" alt="cheap virtual server hosting windows" />
                          </div>
                          <div class="info">
                            <div class="col-md-12 text-center">

                              <div class="price">
                                <h5 class="price-text-color"><span id="currency7">$</span><span id="price7">4.16</span></h5>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/co-in.png"
                              className="img-responsive domain-img"
                              alt="best vps hosting 2020"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency5">$</span>
                                  <span id="price5">6.59</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="col-item">
                          <div className="photo">
                            <img
                              src="img/dotpro.png"
                              className="img-responsive domain-img"
                              alt="best quality support"
                            />
                          </div>
                          <div className="info">
                            <div className="col-md-12 text-center">
                              <div className="price">
                                <h5 className="price-text-color">
                                  <span id="currency8">$</span>
                                  <span id="price8">24.14</span>
                                </h5>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div> */}








                </div>







              </div>
            </div>
          </div>
        </div>
      </section>




    </div>
  )
}
export default SearchBar;