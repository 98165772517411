import React from "react";
import "./Footer.css";
import fb from "../assets/facebook.png";
import twitter from "../assets/twiter.png";
import linkedin from "../assets/linkedin.png";
import inst from "../assets/insta.png";
import ms from "../assets/master-100.jpg"
import ae from "../assets/american express-100.jpg"
import paypal from "../assets/paypal-100.jpg"
import visa from "../assets/visa-100.jpg"
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-links">
          <div className="sb_footer-links-div" style={{ marginTop: 15 }}>
            <h4>Virtual Private server</h4>
            {/* <a href="/window">
              <p>windows virtual server</p>
            </a> */}
             {/* <a href="/hs"> */}
             <Link to="/hs">
              <p>High Speed SSD VSD</p>
              </Link>
            {/* </a> */}
            {/* <a href="/lvps"> */}
            <Link to="/lvps">
              <p>Linux Virtual Private Server</p>
              </Link>
            {/* </a> */}
            {/* <a href="/livps"> */}
            <Link to="/livps">
              <p>Linux india VPS</p>
              </Link>
            {/* </a> */}
            {/* <a href="/windows">
              <p>windows india VPS</p>
            </a> */}
          </div>
          <div className="sb_fotter-links_div" style={{ marginTop: 15 }}>
            <h4>shared hosting</h4>
            {/* <a href="/lsh"> */}
            <Link to="/lsh">
              <p>Linux shared Hosting</p>
              </Link>
            {/* </a> */}
            {/* <a href="/leh"> */}
            <Link to="/leh">
              <p>Linux Enterprise Hosting</p>
            {/* </a> */}
            </Link>
            {/* <a href="/resources">
              <p>Windows shared Hosting</p>
            </a> */}
          </div>

          {/* <div className="sb_footer-links_div">
            <h4>Company</h4>
            <a href="/register">
              <p> MediaStroke</p>
            </a>
            <a href="/transfer">
              <p>Contact</p>
            </a>
            <a href="/price">
              <p>Affiliate Program</p>
            </a>
            <a href="/contact">
              <p>Blog</p>
            </a>
          </div> */}
          {/* <div className="sb_footer-links_div"> */}
            {/* <h4>Servers</h4> */}
            {/* <a href="/servers">
              <p>Dedicated server</p>
            </a> */}
          {/* </div> */}

          <div className="sb_footer-links_div">
            <h4>SSL Certificate</h4>
            {/* <a href="/ssl"> */}
            <Link to="/ssl">
              <p>SSL</p>
              </Link>
            {/* </a> */}
          </div>
          <div className="sb_footer-links_div">
            <h4>Domain</h4>
            {/* <a href="/reg"> */}
            <Link to="/reg">
              <p>Register New Domain</p>
            {/* </a> */}
            </Link>
            {/* <a href="/td"> */}
            <Link to="/td">
              <p>Transfer Domain</p>
            {/* </a> */}
            </Link>
            {/* <a href="/dp"> */}
            <Link to="/dp">
              <p> Domain Pricing List</p>
            {/* </a> */}
            </Link>
          </div>
          <div className="sb_footer-links_div">
            <div className="fb-img"></div>
          </div>

         
          <div className="socialMedia">
            <p>
              <img src={fb} alt="" className="fb-img-logo" />
            </p>
            <p>
              <img src={twitter} alt="" className="twitter-img-logo"/>
            </p>
            <p>
              <img src={linkedin} alt=""className="linkedin-img-logo" />
            </p>
            <p>
              <img src={inst} alt="" className="inst-img-logo"/>
            </p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="sb_footer-below ">
        <div className="sb_footer-copyright">
          <p>@{new Date().getFullYear()}Finabits</p>
        </div>
        <div className="sb_footer-below-links">
          <a href="/">
            <div>
            <img src={ms} alt="" className="master" />
            </div>
          </a>
          <a href="/">
            <div>
            <img src={ae} alt="" className="american" />
            </div>
          </a>
          <a href="/">
            <div>
            <img src={paypal} alt="" className="paypal" />
            </div>
          </a>
          <a href="/">
            <div>
            <img src={visa} alt="" className="visa" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

