
import '../VPS/Common1.css';
import React, { useState } from 'react';

import { Collapse } from 'antd';
import { Link } from "react-router-dom";

const { Panel } = Collapse;



export const Linuxvirtual = () => {
  // const [activeTab1, setActiveTab1] = useState('annually');
  // const [activeTab2, setActiveTab2] = useState('annually');

  // const [activeTab3, setActiveTab3] = useState('annually');
  // const [activeTab4, setActiveTab4] = useState('annually');
  // const [activeTab5, setActiveTab5] = useState('annually');
  // const [activeTab6, setActiveTab6] = useState('annually');
  // const [activeTab7, setActiveTab7] = useState('annually');
  // const [activeTab8, setActiveTab8] = useState('annually');


  // const handleTabClick1 = (tab) => {

  //   setActiveTab1(tab);
  // };
  // const handleTabClick2 = (tab) => {

  //   setActiveTab2(tab);
  // };
  // const handleTabClick3 = (tab) => {

  //   setActiveTab3(tab);
  // };
  // const handleTabClick4 = (tab) => {

  //   setActiveTab4(tab);
  // };
  // const handleTabClick5 = (tab) => {

  //   setActiveTab5(tab);
  // };
  // const handleTabClick6 = (tab) => {

  //   setActiveTab6(tab);
  // };
  // const handleTabClick7 = (tab) => {

  //   setActiveTab7(tab);
  // };
  // const handleTabClick8 = (tab) => {

  //   setActiveTab8(tab);
  // };
  


  // const handleTabClick = (paymentType) => {

  //   console.log(`Selected payment type: ${paymentType}`);
  // };

  return (
    <div>
      <div className="bg-heading">
        <div className="container bg">
          <div className="row c-white">
            <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <h1 className="breadcrumb-heading">Linux Virtual Private Server</h1>
            </div>
            <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="bread-crumb">
              <Link to="/">      <a >Home</a> / <a className="active" title="Linux Virtual Private Server">Linux VPS</a></Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="hosting-block1 parallax-section">
        <div className="container hb1">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
                <img src="/img/hostingblock1.png" alt="Cheap VPS hosting in India" />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
                <div className="domain-title">
                  <h2>Linux Virtual Private Server</h2>
                </div>
                <p className="font18">The Server You've Been Waiting For


</p>
                <ul className="h-list">
                  <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                    <i className="fa fa-chevron-circle-right c-text-green font18"></i> Unlimited Bandwidth with Massive Storage
                  </li>
                  <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                    <i className="fa fa-chevron-circle-right c-text-green font18"></i> Dual Platform Hosting: Linux and Windows



                  </li>
                  <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                    <i className="fa fa-chevron-circle-right c-text-green font18"></i> Benefits of Java Hosting on a Linux Server
                  </li>
                  <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                    <i className="fa fa-chevron-circle-right c-text-green font18"></i> Budget-Friendly Pricing
                  </li>
                  <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                    <i className="fa fa-chevron-circle-right c-text-green font18"></i> Total Administrative Power
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hosting-block2  parallax-section">
        <div className="container hb2">
          <div className="row text-center">
            <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <h2 className="section-heading"> Match Your Needs with a Plan</h2>
              <p className="section-subheading text-muted mb-4">Servers to Meet Every Imaginable Requirement</p>
            </div>

                           
            <div className="col-md-3 col-sm-6 col-xs-6 mb-1 linux-s">
      <div className="host-plan wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title1">1 vCPU / 2GB RAM</h4>

        
        <hr />
        <ul className="plan-list">
          <li className="light">1 vCPU Core</li>
          <li className="dark">2 GB RAM</li>
          <li className="light">40 GB NVMe SSD Storage</li>
          <li className="dark">1 TB Bandwidth</li>
          <li className="light">1 Snapshot</li>
          <li className="dark">Weekly Backups</li>
          <li className="light">Dedicated IP Address</li>
          <li className="dark">Full Root Access</li>
          <li className="light">AI Assistant</li>
          <li className="dark"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div className="h-vps-pricing-card__price">
      <div className="h-pricing-card__discount">
        <span className="h-price h-vps-pricing-card__old-price h-price--text-gray" country-code="IN">
          <span className="h-price__currency t-body-strikethrough">₹</span>
          <span className="h-price__number t-body-strikethrough">999.00</span>
        </span>
        <span className="h-discount-tag t-body-2 h-discount-tag-text-primary-dark h-discount-tag-bg-primary-light">SAVE 55%</span>
      </div>
      <div className="h-dynamic-size-price h-vps-pricing-card__current-price ready" typography-classes="[object Object]" price="449" suffix="mo" country-code="IN" color="meteorite-dark" style={{ fontSize: '43px' }}>
        <span className="h-price h-price--text-meteorite-dark" country-code="IN">
          <span className="h-price__currency t-body-large" style={{ fontSize: '0.416667em' }}>₹</span>
          <span className="h-price__number t-header-currency">449.00</span>
          <span className="h-price__suffix t-body-large" style={{ fontSize: '0.416667em' }}>/mo</span>
        </span>
      </div>
       </div>
 
     <div id="sls_btn" className="host-btn">
          <a className="btn blackbox active" href="#">Add to Cart</a>
        </div>
      </div>
             </div>
            
              
             <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title2">2 vCPU / 4GB RAM</h4>

       

    



        <hr />
        <ul className="plan-list">
          <li className="light">2 vCPU Cores</li>
          <li className="dark">4 GB RAM</li>
          <li className="light">100 GB NVMe SSD Storage</li>
          <li className="dark">2 TB Bandwidth
</li>
          <li className="light">1 Snapshot</li>
          <li className="dark">Weekly Backups</li>
          <li className="light">Dedicated IP Address</li>
          <li className="dark">Full Root Access</li>
          <li className="light">AI Assistant</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>




        <div className="h-vps-pricing-card__price">
      <div className="h-pricing-card__discount">
        <span className="h-price h-vps-pricing-card__old-price h-price--text-gray" country-code="IN">
          <span className="h-price__currency t-body-strikethrough">₹</span>
          <span className="h-price__number t-body-strikethrough">1399.00</span>
        </span>
        <span className="h-discount-tag t-body-2 h-discount-tag-text-primary-dark h-discount-tag-bg-primary-light">SAVE 53%</span>
      </div>
      <div className="h-dynamic-size-price h-vps-pricing-card__current-price ready" typography-classes="[object Object]" price="449" suffix="mo" country-code="IN" color="meteorite-dark" style={{ fontSize: '43px' }}>
        <span className="h-price h-price--text-meteorite-dark" country-code="IN">
          <span className="h-price__currency t-body-large" style={{ fontSize: '0.416667em' }}>₹</span>
          <span className="h-price__number t-header-currency">659.00</span>
          <span className="h-price__suffix t-body-large" style={{ fontSize: '0.416667em' }}>/mo</span>
        </span>
      </div>
       </div>



        <div id="slb_btn" className="host-btn">
          <a className="btn blackbox active" href="#">Add to Cart</a>
        </div>
      </div>
             </div>



              <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">4 vCPU / 8GB RAM</h4>

       
         



        <hr />
        <ul className="plan-list">
          <li className="light">4 vCPU Cores</li>
          <li className="dark">8 GB RAM</li>
          <li className="light">200 GB NVMe SSD Storage</li>
          <li className="dark">4 TB Bandwidth</li>
          <li className="light">1 Snapshot</li>
          <li className="dark">Weekly Backups</li>
          <li className="light">Dedicated IP Address</li>
          <li className="dark">Full Root Access</li>
          <li className="light">AI Assistant</li>
          <li className="dark"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>


        <div className="h-vps-pricing-card__price">
      <div className="h-pricing-card__discount">
        <span className="h-price h-vps-pricing-card__old-price h-price--text-gray" country-code="IN">
          <span className="h-price__currency t-body-strikethrough">₹</span>
          <span className="h-price__number t-body-strikethrough">2799.00</span>
        </span>
        <span className="h-discount-tag t-body-2 h-discount-tag-text-primary-dark h-discount-tag-bg-primary-light">SAVE 66%</span>
      </div>
      <div className="h-dynamic-size-price h-vps-pricing-card__current-price ready" typography-classes="[object Object]" price="449" suffix="mo" country-code="IN" color="meteorite-dark" style={{ fontSize: '43px' }}>
        <span className="h-price h-price--text-meteorite-dark" country-code="IN">
          <span className="h-price__currency t-body-large" style={{ fontSize: '0.416667em' }}>₹</span>
          <span className="h-price__number t-header-currency">949.00</span>
          <span className="h-price__suffix t-body-large" style={{ fontSize: '0.416667em' }}>/mo</span>
        </span>
      </div>
       </div>



        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="#">Add to Cart</a>
        </div>
      </div>
              </div>


               <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">4 vCPU / 16GB RAM</h4>

      

      
        <hr />
        <ul className="plan-list">
          <li className="light">8 vCPU Cores</li>
          <li className="dark">16 GB RAM</li>
          <li className="light">200 GB NVMe SSD Storage</li>
          <li className="dark">8 TB Bandwidth</li>
          <li className="light">1 Snapshot</li>
          <li className="dark">Weekly Backups</li>
          <li className="light">Dedicated IP Address</li>
          <li className="dark">Full Root Access</li>
          <li className="light">AI Assistant</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>

         
        <div className="h-vps-pricing-card__price">
      <div className="h-pricing-card__discount">
        <span className="h-price h-vps-pricing-card__old-price h-price--text-gray" country-code="IN">
          <span className="h-price__currency t-body-strikethrough">₹</span>
          <span className="h-price__number t-body-strikethrough">
      4,999.00</span>
        </span>
        <span className="h-discount-tag t-body-2 h-discount-tag-text-primary-dark h-discount-tag-bg-primary-light">SAVE 66%</span>
      </div>
      <div className="h-dynamic-size-price h-vps-pricing-card__current-price ready" typography-classes="[object Object]" price="449" suffix="mo" country-code="IN" color="meteorite-dark" style={{ fontSize: '43px' }}>
        <span className="h-price h-price--text-meteorite-dark" country-code="IN">
          <span className="h-price__currency t-body-large" style={{ fontSize: '0.416667em' }}>₹</span>
          <span className="h-price__number t-header-currency">1,699.00</span>
          <span className="h-price__suffix t-body-large" style={{ fontSize: '0.416667em' }}>/mo</span>
        </span>
      </div>
       </div>





        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="#">Add to Cart</a>
        </div>
      </div>
                </div>




  

          </div>
        </div>

      </div>


      <div className="live-demo">
        <div className="container ld">

          <div className="c-white text-center wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="section-heading mb-2">Contact for more Information</div>
            <p className="section-subheading">Reach Out with Your Questions


</p>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
              <form id="form" >
                <div className="row_ld">
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 email-ld">
                    <label className="email">Email</label>
                    <input className="input-box" id=" email" name="email" placeholder="Email Address" type="text" ></input>
                  </div>

                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <label className="password">Password</label>
                    <input className="input-box" id="password" name="password" placeholder="Password" type="text"></input>
                  </div>


                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <label className="Description">Description</label>
                    <textarea className="input-box" placeholder="Define VPS Specification" name="desc" id="desc" required></textarea>
                  </div>


                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <button className="searchsubmit" >
                      {/* <span className="fa fa-cloud-upload"></span> */}
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>



        </div>
      </div>


      <div className="hosting-features mb-3">

        <div className="container hf">

          <div className="row justify-content-center mb-5">
            <div className="col-md-8 text-center heading-section">
              <h2 className="">
                <span>Linux  </span>
                VPS Highlights
              </h2>
            </div>
          </div>


          <div class="rows">
            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fa fa-cubes fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/stellar (1).png"></img>
                  <h4>Stellar Performance</h4>
                </div>
                <div className="pt-3 p-txt">
                VPS ensures top-notch performance for computational tasks, large-scale database operations, dynamic web applications, daemon processes, and efficient backup/restore procedures.
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fab fa-snapchat fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/captures.png"></img>
                  <h4>Capture Snapshots</h4>
                </div>

                <div className="pt-3 p-txt">
                Our VPS is built on KVM architecture, allowing you to capture snapshots for effortless and quick restoration.

                </div>

              </div>
            </div>

            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fa fa-lock fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/secure-data.png"></img>
                  <h4>Save on SSL and Secure a Dedicated IP</h4>
                </div>

                <div className="pt-3 p-txt">
                SSL certificates and dedicated IP addresses play a pivotal role in elevating security levels, curbing spam, and enhancing your website's SEO performance.

                </div>

              </div>
            </div>
          </div>

          <div className="rows">
            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fa fa-cog fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/control-center.png"></img>
                  <h4>User-Friendly Control Panels</h4>
                </div>

                <div className="pt-3 p-txt">
                System administration is complex; leverage licensed cPanel/WHM for Linux and Plesk Onyx for Windows servers.                </div>

              </div>
            </div>


            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fab fa-windows fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/operating-system.png"></img>
                  <h4>Advanced OS Choices</h4>
                </div>

                <div className="pt-3 p-txt">
                Select your preferred OS: Linux (CENT OS 7.6, CENT OS 6.9, UBUNTU 16.04, UBUNTU 17.04) or Windows (WINDOWS SERVER 2012R2 STANDARD 64-BIT).                                               </div>

              </div>
            </div>


            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  {/* <i className="fa fa-eye fa-4x c-text-green mb-2"></i> */}
                  <img src="/img/cctv-camera.png"></img>
                  <h4> Surveillance as Protector


</h4>
                </div>

                <div className="pt-3 p-txt">
                Our monitoring features provide real-time notifications. Easily track and receive sudden alerts for all your services, CPU usage, memory consumption, and security status.
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>


      <div className="live-demoo c-white">
        <div className="container ld">
          <div className="col-sm-12 text-center mb-4">
            <div className="domain-title">
              <h2>Linux Shared Hosting: Advanced Tools and Options</h2><br />
            </div>
          </div>


          <div className="row_advancedf mb-4">
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
              <i className="fa fa-check-square fa-5x c-text-green"></i>
            </div>
            <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h4>Rapid Deployment and Easy Control</h4>
              <p>Harness the power of cPanel and Plesk for rapid setup, effortless CMS installations (e.g., WordPress, Joomla, Magento), and 
                convenient centralized management through a single, intuitive interface.
              </p>
            </div>
          </div>

          <div className="row_advancedf mb-4">
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
              <i className="fa fa-tasks fa-5x c-text-green"></i>
            </div>
            <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h4>Hosting Provider Solutions</h4>
              <p>Transform into a hosting provider with cPanel/WHM and Plesk panels. Enjoy centralized control, plan customization, and the ability to serve customers and resellers. Amplify your services
                 with plugins, advanced security, and timely alerts for a profitable hosting venture.

              </p>
            </div>
          </div>

          <div className="row_advancedf mb-4">
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
              <i className="fa fa-fast-forward fa-5x c-text-green"></i>
            </div>
            <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h4>No-Limits Speed and Data</h4>
              <p>Experience unlimited bandwidth with a minimum speed of 100Mbps on all plans, with no additional charges for extra usage. For unparalleled speed, inquire about dedicated 1Gbps lines.

              </p>
            </div>
          </div>

          <div className="row_advancedf mb-4">
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
              <i className="fa fa-cogs fa-5x c-text-green"></i>
            </div>
            <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h4>End-to-End Managed Services</h4>
              <p>Our fully managed solutions are custom-fit for your applications. We'll recommend the right plan, set it up, ensure seamless deployment, and take care of snapshots, backups, and monitoring. Additionally, we implement firewall rules and bolster OS security.
              </p>
            </div>
          </div>


          <div className="row_advancedf mb-4">
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
              <i className="fa fa-graduation-cap fa-5x c-text-green"></i>
            </div>
            <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <h4>Managed Hosting Excellence</h4>
              <p> Rely on our fully managed servers and 24/7 dedicated support for your application of choice. We create a secure, high-performance environment with dedicated IPs, SSL, firewalls, automated backups, performance enhancements, file permissions, snapshots, and continuous monitoring</p>
            </div>
          </div>



        </div>
      </div>


      <div className="faq">
        <div className="container fq">
          <div className="section-heading text-center mb-5 mb-2">Frequently Asked Questions</div>


          <Collapse defaultActiveKey={['1']}>
            <Panel header="What is a VPS" key="1" >
              <p>
                A Linux Virtual Private Server is an isolated server environment created after partitioning a physical server into multiple virtual servers with each server having its own share of dedicated resources like CPU, memory and disk space. Each VPS is absolutely ‘private’ from the other servers sharing the same host node and each one functions just like any other regular server with root SSH access. With multiple Linux Virtual Private Servers functioning from one physical server, the operating costs are significantly
                reduced which in turn makes VPS hosting an affordable alternative to having your own dedicated server
              </p>

            </Panel>
            <Panel header="What are the upsides of VPS Hosting?" key="2">
              <p>The three primary points of interest that accompany VPS Hosting are: Complete Isolation: Your VPS bundle and its applications stay unaffected independent of what another clients does on the server
                Root Access: This enables you to introduce any perfect utilization of your decision
                Ensured Resources: Irrespective of any circumstance, the CPU, RAM, HDD and data transmission that have been designated to your bundle will consistently be accessible for your applications.</p>
            </Panel>
            <Panel header="What Virtualization software do you use?" key="3">
              <p>We use Kernel-based Virtual Machine (KVM).</p>


            </Panel>
            <Panel header="Would i be able to redesign between VPS plans?" key="4">
              <p>Truly. Our VPS servers are versatile. This implies you can update your current arrangement whenever and immediately. Be that as it may, we don't bolster plan downsize.</p>

            </Panel>
            <Panel header="Would i be able to introduce any sort of programming on my VPS Package? " key="5">
              <p>Since you have full root get to, you can introduce any product that is perfect on your VPS bundle. Notwithstanding, any product that disregards our Acceptable Usage Policy won't be permitted.</p>


            </Panel>
            <Panel header="Can I install control panels on my VPS package?" key="6">
              <p>Yes. We offer cPanel & Plesk as the hosting panels that you can use on your servers. cPanel & Plesk are auto-installed, if they are added during the server purchase.</p>
            </Panel>

            <Panel header="Do you give Graphic User Interface (GUI) for your Linux VPS? " key="7">
              <p>Our Linux VPS doesn't accompany GUI as a matter of course, yet we can introduce it for you and empower the VNC get to in the event that you present a help demand. You can likewise introduce GUI without anyone else through SSH.H.</p>
            </Panel>

            <Panel header="How long will it take for my VPS to be setup?" key="8">
              <p>When you pursue the ideal VPS facilitating bundle, the arrangement procedure will be attempted immediately by our group. You can begin utilizing your virtual server immediately after accepting the server and control board logins.</p>
            </Panel>

            <Panel header="Do you provide any server management or backups?" key="9">
              <p>We don't have any backup solution right now. However, we emphatically prescribe that you keep up a remote reinforcement through cPanel or Plesk to maintain a strategic distance from any issues during a disastrous episode.</p>
            </Panel>

            <Panel header="How do you do cancellations?" key="10">
              <p>Servers are dropped toward the finish of each charging period or promptly relying upon what the clients wants. Client is required to make a dropping solicitation at any rate 72 hours before their recharging date to guarantee it very well may be prepared in time. After a server is dropped all client information is erased and you won't have the option to recoup it on the off chance that you choose to reactivate later on.</p>
            </Panel>
          </Collapse>



        </div>
      </div>


    </div>
  )
}