

import React from 'react';
import './Component/Common.css';
import { Header } from './Component/Header';

import { Index } from './Component/Index';
import { Footer } from './Component/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Linuxvirtual } from './Component/VPS/Linuxvirtual';
import { Windowsvirtual } from './Component/VPS/Windowsvirtual';
import { Linuxindiavps } from './Component/VPS/Linuxindiavps';
import { Dedicated } from './Component/Server/Dedicated';
import { Linuxsharedhosting } from './Component/SharedHosting/Linuxsharedhosting';
import { Linuxenterprisehosting } from './Component/SharedHosting/Linuxenterprisehosting';
import { Windowssharedhosting } from './Component/SharedHosting/Windowssharedhosting';
import { Bestwebhosting } from './Component/Bestwebhosting';
import { Hosting } from './Component/Hosting';
import { Highspeedssdvsd } from './Component/VPS/Highspeedssdvsd';
import { Windowsindiavps } from './Component/VPS/Windowsindiavps';
import { Sslcertificate } from './Component/SSL/Sslcertificate';
import { Transferdomain } from './Component/Domain/Transferdomain';
import { Register } from './Component/Domain/Register';
import { Domainpricing } from './Component/Domain/Domainpricing';
import { About } from './Component/company/About';
import { Contact } from './Component/company/Contact';
import { Login } from './Component/Login';
// import Signup1 from './Component/Signup1';


// import { Register } from './Component/Domain/Register';



function App() {
  return (
    <div className="App">



      <BrowserRouter>
        <Header />




        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/hs' element={<Highspeedssdvsd />} />
          <Route path='/lvps' element={<Linuxvirtual />} />
          <Route path='/wvps' element={<Windowsvirtual />} />
          <Route path='/livps' element={<Linuxindiavps />} />
          <Route path='/wivps' element={<Windowsindiavps />} />
          <Route path='/ds' element={<Dedicated />} />
          <Route path='/lsh' element={<Linuxsharedhosting />} />
          <Route path='/leh' element={<Linuxenterprisehosting />} />
          <Route path='/wsh' element={<Windowssharedhosting />} />
          <Route path='/reg' element={<Register />} />
          <Route path='/td' element={<Transferdomain />} />
          <Route path='/dp' element={<Domainpricing />} />
          <Route path='/ssl' element={<Sslcertificate />} />

          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
         
           <Route path='/login' element={<Login/>} />
          {/* <Route path='/sign1' element={<Signup1 />} />  */}

        </Routes>

        <Footer />
      </BrowserRouter>





    </div>
  );
}

export default App;
