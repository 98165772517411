import React from "react";
import '../VPS/Common1.css';


import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Dedicated = () =>{

 

    return(
    <div>
        <div className="bg-heading">
      <div className="container bg">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <h1 className="breadcrumb-heading">Dedicated Server Germany</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="bread-crumb">
              <a href="/">Home</a> / <a href="virtual-private-server" className="active" title="Linux Virtual Private Server">Dedicated Servers</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="hosting-block1 parallax-section">
      <div className="container hb1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <img src="\img\hostingblock1.png" alt="Cheap VPS hosting in India" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
              <div className="domain-title">
                <h2>Dedicated Server from Germany</h2>
              </div>
              <p className="font18">What else you require if you have your own Server</p>
              <ul className="h-list">
                <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Unlimited Bandwidth with Massive Storage
                </li>
                <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Linux and Windows Platforms
                </li>
                <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Java Hosting solution with Linux Server
                </li>
                <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Effective Cost (15% OFF - Use Code: ALLVPS15)
                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Complete root access
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div className="hosting-block2  parallax-section">
        <div className="container hb2">
           <div className="row text-center">
             <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
               <h1 className="h1-heading">Dedicated Server Germany</h1>
                <p className="section-subheading text-muted mb-4">The server on lease for private use without sharing with else one is the dedicated server. The server resources are fully controlled by end customer like an operating system, hardware, applications, permissions etc. End customers have full root/administrator access to manage his server by own</p>
             </div>

             <div className="col-12 col-sm-12 col-md-12 col-lg-14 col-xl-12 mb-1 col-table wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{visibility:'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown'}}>
                

               <div className="row">
                 <div className="st-wrapper table-responsive">

                 <table className="compare-table">
                     <thead>
                        <tr className="compare-heading">
                           <th colSpan="4">Linux Metal Servers</th>
                        </tr>
                         <tr className="title-compare-table">
                            <td>PLAN</td>
                            <td>CPU AND RAM</td>
                            <td>STORAGE AND BANDWIDTH</td>
                           <td>PRICE</td>
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SAT-4A3X2</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>INTEL XEON E3-1225V2 4C/4T 3.2GHZ 16GB DDR3 1333 MHZ</td>
                            <td>SOFTWARE RAID 3X2TB SATA, UNLIMITED BANDWIDTH</td>
                   
                            <td>
                              
                              <span id="currency1">$</span>
                              <span itemprop="price" id="price1">118.58</span>
                              /
                              <span className="unit">mo<br></br></span>

                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>

                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SSD-4B2X4</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td> INTEL XEON E3-1245V2 4C/8T 3.4GHZ 32GB DDR3 1333 MHZ </td>
                            <td>SOFTWARE RAID 2X480 GB SSD, UNLIMITED BANDWIDTH</td>
                          

                            <td>
                            <span id="currency1">$</span>
                              <span itemprop="price" id="price1">131.72</span>
                              /
                              <span className="unit">mo<br></br></span>                                 
 
                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SAT-4B3X2</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>INTEL XEON E3-1245V2 4C/8T 3.4GHZ 32GB DDR3 1333 MHZ</td>
                            <td> SOFTWARE RAID 3X2TB SATA, UNLIMITED BANDWIDTH</td>
                       

                            <td>
                            <span id="currency1">$</span>
                              <span itemprop="price" id="price1">131.72</span>
                              /
                              <span className="unit">mo<br></br></span>                                 
 
                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SSD-4C2X4</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>INTEL XEON E5-1630V3 4C/8T 3.7GHZ 64GB DDR4 ECC 2133 MHZ</td>
                            <td>SOFTWARE RAID 4X2TB SATA, UNLIMITED BANDWIDTH</td>
                         
                            <td>
                            <span id="currency1">$</span>
                              <span itemprop="price" id="price1">197.64</span>
                              /
                              <span className="unit">mo<br></br></span>
                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SAT-6C4X2</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>	INTEL XEON E5-1630V3 6C/12T 3.5GHZ 64GB DDR4 ECC 2133 MHZ</td>
                            <td> SOFTWARE RAID 2X480 GB SSD, UNLIMITED BANDWIDTH</td>
                          
                            <td>

                            <span id="currency1">$</span>
                              <span itemprop="price" id="price1">219.56</span>
                              /
                              <span className="unit">mo<br></br></span>
                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                 <span className="compare-name" id="title1">LINUX METAL - XEON-SAT-6C4X2</span>
                                 <i className="fab fa-linux fa-3x" aria-hidden="true"></i>
                            </td>
                            <td>INTEL XEON E5-1630V3 6C/12T 3.5GHZ 64GB DDR4 ECC 2133 MHZ</td>
                            <td> SOFTWARE RAID 4X2TB SATA, UNLIMITED BANDWIDTH</td>
                         
                            <td>
                            <span id="currency1">$</span>
                              <span itemprop="price" id="price1">219.56</span>
                              /
                              <span className="unit">mo<br></br></span>
                            <div id="sls_btn">
                                   <a href="https://mediastroke.com/store/index.php?rp=/store/linux-vps/linux-vps-hl123&amp;billingcycle=annually&amp;currency=2" class="btn-green">BUY</a>
                            </div>
                            </td>
                            
                        </tr>


                       

                      

                      </tbody>
                                    
                    </table>

                 </div>

               </div>
             </div>

           </div>
        </div>

     </div>


              


               <div className="hosting-features mb-3">

                   <div className="container hf">

                      <div className="row justify-content-center mb-5">
                          <div className="col-md-8 text-center heading-section">
                             <h2 className="">
                              <span> Features of Dedicated Server </span>
                                
                             </h2>
                          </div>
                       </div>


                 <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa fa-unlock-alt fa-4x c-text-green mb-2"></i>
                                       <h4>Full Root Access</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                   The customer has full root access to the server which gives him the freedom to install any 
                                   application & set permissions to it.
                                    
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fab fa-check-square fa-4x c-text-green mb-2"></i>
                                       <h4>Unlimited Bandwidth</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     We provide unlimited that is unmetered bandwidth with dedicated servers in each plan. The network 
                                     link has 100mbps speed & 1gbps link is also available with addon price.
                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-cubes fa-4x c-text-green mb-2"></i>
                                       <h4>Ultimate Performance</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     The DDOS protection at firewall level protects DDOS attack. 
                                     The SSD for high-speed performance and HDD for mass storage both are available.
                                    
                                   </div>

                               </div>
                          </div>
                 </div>                       

                 <div className="rows">                    
                              <div class="columns">
                                   <div class="cards">
                                     <div className="features mb-5">
                                          <i className="fa fa-trophy fa-4x c-text-green mb-2"></i>
                                          <h4>99.91% Uptime Guarantee</h4>
                                     </div>

                                       <div className="pt-3 p-txt">
                                       We claim for uptime assurance as we know the importance of your server and for that, we continuously measure the insights of your server.                                              
                                       </div>

                               </div>
                              </div>


                               <div class="columns">
                                  <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fab fa-linux fa-4x c-text-green mb-2"></i>
                                       <h4>Latest OS & Control panels</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     We provide updated licenses of operating systems like windows
                                      server 2016 standard (64 Bit) and latest Control Panel license like cPanel & Plesk panel.                                        </div>

                                  </div>
                               </div>

                               
                               <div class="columns">
                                 <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-life-ring fa-4x c-text-green mb-2"></i>
                                       <h4>Outstanding Support</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Enjoy direct technical support as we feel you are the dedicated customer. Out chat, call & ticket support is available for 24/7/365.
                                   </div>
                               </div>
                               </div>
                   </div>


                   </div>

               </div>


             
                        

                        <div className="faq">
                            <div className="container fq">
                            <div className="section-heading text-center mb-5 mb-2">Frequently Asked Questions</div>
                                  
                                      
                            <Collapse defaultActiveKey={['1']}>
                             <Panel header="Why Dedicated Server?" key="1" >
                                <p> 
                                    For businesses, enterprises, high traffic websites and to host a large volume of customers website as a hosting provider the dedicated server is a right decision. 
                                    The dedicated server is the best solution also for high profile agencies for a reliable experience of service. The reseller hosting provider also needs a dedicated server to create the number of resellers and give support by own using root access and the last one is the E-commerce websites.
                                </p>
           
                             </Panel>
                            <Panel header="Is control panel required?" key="2">
                                <p>It is all up to you. If you don’t have good skills in system administration to fulfill your purpose then you can manage your server without any control panel. If you don’t wish to do a hectic job then we 
                                   recommend you to go with the control panel like cPanel or Plesk panel. Which gives you all features to manage your resources like E-mails, databases, SSL, IP, DNS, HTTP server etc.</p>
                              </Panel>

        </Collapse>
        


                            </div>
                        </div>
   

    </div>
    )
}