import React ,{useState} from "react";
  import '../VPS/Common1.css';

  import { Card, List, Button } from 'antd';
import { Collapse } from 'antd';
import { Link } from "react-router-dom";

const { Panel } = Collapse;

export const Linuxenterprisehosting = () =>{

  // const [activeTab, setActiveTab] = useState('triennially');

  // const handleTabClick = (tab) => {

  //   setActiveTab(tab);
  // };


  const [activeTab1, setActiveTab1] = useState('triennially');
  const [activeTab2, setActiveTab2] = useState('triennially');
  const [activeTab3, setActiveTab3] = useState('triennially');
  const [activeTab4, setActiveTab4] = useState('triennially');

  const handleTabClick1 = (tab) => {
    setActiveTab1(tab);
  };

  const handleTabClick2 = (tab) => {
    setActiveTab2(tab);
  };

  const handleTabClick3 = (tab) => {
    setActiveTab3(tab);
  };
  const handleTabClick4 = (tab) => {
    setActiveTab4(tab);
  };

    return(
    <div>
        <div className="bg-heading">
      <div className="container bg">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <h1 className="breadcrumb-heading" >Linux Enterprise Hosting</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="bread-crumb">
            <Link to="/">  <a >Home</a> / <a  className="active" title="Linux Virtual Private Server">Linux Enterprise Hosting</a></Link>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="hosting-block1 parallax-section">
      <div className="container hb1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <img src="/img/hostingblock1.png" alt="Cheap VPS hosting in India" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
              <div className="domain-title">
                <h2>Reliable Linux Hosting for Enterprises</h2>
              </div>
              <p className="font18">Enterprise-Focused Setup with 24x7 Assistance


</p>
              <ul className="h-list">
                <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Achieve 20x Faster Page Loading



                </li>
                <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i>Full SSD Storage with Servers in India



                </li>
                <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i>  No Additional Cost for SSL and IMUNIFY360 WAF Security



                </li>
                <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i>  99.9% Uptime Assurance with Swift CPU and RAM Speed



                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Unlimited Daily Backups



                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Free CDN Integration with Continuous 24/7 Technical Support



                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    


    <div className="hosting-block2  parallax-section hosting">
        <div className="container hb2">
           <div className="row text-center">
             <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
               <h2 className="section-heading">Secure and Scalable Linux Enterprise Hosting with Control Panel


</h2>
                <p className="section-subheading text-muted mb-4"> Elevate Your Hosting Experience: Linux Enterprise Hosting with User-Friendly cPanel.  </p>
             </div>
               

             <div className="col-md-3 col-sm-6 col-xs-6 mb-1 linux-s">
      <div className="host-plan wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title1">LINUX ENTERPRISE - E1</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab1 === 'triennially' ? 'active' : 'fade'}`} id="triennially_1">
            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="triennially1">$1.36/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab1 === 'biennially' ? 'active' : 'fade'}`} id="biennially_1">
            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="biennially1">$1.40/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab1 === 'annually' ? 'active' : 'fade'}`} id="annually_1">

            <span className="host-price" id="annually1">$1.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab1 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('triennially')}
                                  href="#triennially_1">36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab1 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('biennially')}
                                  href="#biennially_1">24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab1 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('annually')}
                                  href="#annually_1">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">1GB Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">25 E-mail Accounts</li>
          <li className="dark">1 Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="sls_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
             </div>
            
              
             <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title2">LINUX ENTERPRISE - E2</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab2 === 'triennially' ? 'active' : 'fade'}`} id="triennially_2">
    
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially2">$4.18/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab2 === 'biennially' ? 'active' : 'fade'}`} id="biennially_2">
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially2">$4.30/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab2 === 'annually' ? 'active' : 'fade'}`} id="annually_2">
            <span className="host-price" id="annually2">$4.60/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('triennially')}
                                  href="#triennially_2">36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('biennially')}
                                  href="#biennially_2">24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('annually')}
                                  href="#annually_2">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">10 Websites</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slb_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
             </div>



              <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">LINUX ENTERPRISE - E3</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab3 === 'triennially' ? 'active' : 'fade'}`} id="triennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially3">$5.00/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab3 === 'biennially' ? 'active' : 'fade'}`} id="biennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially3">$5.14/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab3 === 'annually' ? 'active' : 'fade'}`} id="annually_3">
            <span className="host-price" id="annually3">$5.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('triennially')}
                                  href="#triennially_3">36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('biennially')}
                                  href="#biennially_3">24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('annually')}
                                  href="#annually_3">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">Unlimited Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
              </div>


               <div className="col-md-3 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">LINUX ENTERPRISE - E4</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab4 === 'triennially' ? 'active' : 'fade'}`} id="triennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially3">$5.00/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab4 === 'biennially' ? 'active' : 'fade'}`} id="biennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially3">$5.14/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab4 === 'annually' ? 'active' : 'fade'}`} id="annually_3">
            <span className="host-price" id="annually3">$5.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab4 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick4('triennially')}
                                  href="#triennially_3">36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab4 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick4('biennially')}
                                  href="#biennially_3">24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab4 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick4('annually')}
                                  href="#annually_3">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">Unlimited Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
                </div>
        
            
           

              
                        
           </div>
        </div>

     </div>


    


               <div className="live-demo">
                   <div className="container ld">
                   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="domain-title text-center">
        <h2>IMUNIFY360-Protected Linux Enterprise Hosting</h2>
      </div>
      <div className="row lshp">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <img src="/img/imunify360.webp"  className="cpanel-img" alt="Linux cPanel Hosting" />
        </div>
        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 wow fadeInDown mb-3 textp" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <p>IMUNIFY360 offers top-tier server protection, featuring the best-in-class WAF Security, ideal for CMS systems like WordPress, Joomla, Drupal, Magento, and more. It shields your hosting from real-time IP Reputation threats, Honeypots, DoS Attacks, and Malware Injections. Beyond HTTPS, IMUNIFY360 safeguards against FTP, POP3, IMAP, and other TCP-based DoS attacks. With its advanced Malware Detection module, it employs cutting-edge techniques for analysis. Any attack on an IMUNIFY360-protected server promptly blacklists the malicious IP, ensuring your hosting's safety.</p>
          
        </div>
      </div>
    </div>


                       
                   </div>
               </div>


               <div className="hosting-features mb-3">

                   <div className="container hf">

                      <div className="row justify-content-center mb-5">
                          <div className="col-md-8 text-center heading-section">
                             <h2 className="">
                              <span>Linux Enterprise    </span>
                              Hosting Highlights 

                             </h2>
                          </div>
                       </div>


                       <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa  fa-hdd  fa-4x c-text-green mb-2"></i>
                                       <h4>Swift: Powerful CPU/RAM</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                   Achieve lightning-fast speeds with our dedicated CPU and RAM, the driving force behind high-performance websites.

                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-lock fa-4x c-text-green mb-2"></i>
                                       <h4>High-Grade Security</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Linux servers come fortified with top-tier security, featuring integrated WAF security solutions. Our trusted security partner, IMUNIFY360, delivers a high level of security solutions to ensure your peace of mind.
                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-briefcase  fa-4x c-text-green mb-2"></i>
                                       <h4>Hosting with Full SSD</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Enterprise server: Full SSD for 20X faster page loads, swift data access.
                                    
                                   </div>

                               </div>
                          </div>
                 </div>     

                 <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa fa-code fa-4x c-text-green mb-2"></i>
                                       <h4>Tech Solutions</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                   Linux server hosting with CGI, PHP, Python, MySQL, SSL, and security features.
Simplified CMS platform installation for WordPress, Joomla, and more with Softaculous.
                                    
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-briefcase fa-4x c-text-green mb-2"></i>
                                       <h4> CMS Platforms</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Our Softaculous software suite simplifies the installation of free open-source CMS software like WordPress, Joomla, OS-Commerce, PrestaShop, and more. With just three easy steps, you can have your chosen CMS up and running in no time.
                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-arrow-up  fa-4x c-text-green mb-2"></i>
                                       <h4>Smooth Upgrading</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Effortlessly adjust your Linux hosting plan with pro-rata charges, all from your user login for hassle-free upgrades or downgrades.
                                    
                                   </div>

                               </div>
                          </div>
                 </div>                       

                 <div className="rows">                    
                              <div class="columns">
                                   <div class="cards">
                                     <div className="features mb-5">
                                          <i className="fa fa-envelope fa-4x c-text-green mb-2"></i>
                                          <h4>Email Services</h4>
                                     </div>

                                       <div className="pt-3 p-txt">
                                       Our Linux server's email service is business-ready, delivering reliable performance without delivery failures or spam issues.


.                                       </div>

                               </div>
                              </div>


                               <div class="columns">
                                  <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-tasks fa-4x c-text-green mb-2"></i>
                                       <h4> Multifunctioning</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Linux Enterprise Servers excel in multifunctioning, enabling the simultaneous execution of multiple programs in the background while users seamlessly work on other tasks, ensuring uninterrupted operations.
                                      </div>

                                  </div>
                               </div>

                               
                               <div class="columns">
                                 <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-eye fa-4x c-text-green mb-2"></i>
                                       <h4>Supervision</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Supervision features are available, and you'll receive instant notifications to oversee all your services, CPU utilization, memory consumption, and security diligently.

                                   </div>
                               </div>
                               </div>
                   </div>


                   </div>

               </div>


               <div className="live-demoo c-white">
                  <div className="container ld">
                             <div className="col-sm-12 text-center mb-4">
                                     <div className="domain-title">
                                          <h2>Linux Enterprise Hosting's Advanced Capabilities</h2><br />
                                     </div>
                              </div>


                              <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-check-square fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>User-Friendly Control Panels</h4>
                                               <p>Save your valuable time using cPanel, it provides ready to code environment. Single click installation and upgrade of cms like WordPress, Joomla, Magento etc. Do easy administration using cPanel or Plesk from a single window.
                                                </p>
                                     </div>
                               </div>

                           

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-fast-forward fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Endless Fast Traffic</h4>
                                               <p>Every plan offers unlimited bandwidth and a minimum 100Mbps speed, without any additional costs for extra usage.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-cogs fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Premium Managed Support</h4>
                                               <p>We offer fully managed solutions tailored to your applications, from setup to monitoring, including firewall rules and OS hardening.
                                                </p>
                                     </div>
                               </div>


                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-graduation-cap fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Choose our expert consultation.</h4>
                                      <p> Choose fully managed servers with 24/7 support. We guarantee security, performance, and safety with dedicated IPs, SSL, firewalls, backups, and more.</p>
                                     </div>
                               </div>



                  </div>
               </div>
                        

                        
   

    </div>
    )
}