import React from "react";
import { Hero } from "./Hero";
import { Services } from "./Services";
import { Feature } from "./Feature";
import { Hosting } from "./Hosting";
 import { Trustpilot } from "./Trustpilot";
import { Highspeed } from "./Highspeed";
import SearchBar from "./SearchBar";
import { Bestwebhosting } from "./Bestwebhosting";
// import { Deal } from "./Deal";






export const  Index = ()=>{
    return(
       <div>
        <Hero/>
      <SearchBar/>
        <Feature/>
        <Services/>
         {/* <Hosting/>  */}
        {/* <Trustpilot/> */}
        <Highspeed/>
        <Bestwebhosting/>
        {/* <Deal/> */}
 

        </div>
    )
}