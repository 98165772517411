import React from "react";
import '../VPS/Common1.css';
import { Collapse } from 'antd';
import { Link } from "react-router-dom";
const { Panel } = Collapse;
export const Transferdomain = () => {
    const paymentType = "somePaymentType"; // Initialize paymentType with the desired value
    console.log(`Selected payment type: ${paymentType}`);

  return (
    <div>
      <div className="bg-heading">
        <div className="container">
          <div className="row c-white">
            <div
              className="col-sm-9 wow fadeInDown"
              style={{ visibility: 'visible', animationName: 'fadeInDown' }}
            >
              <h1 className="breadcrumb-heading" >Domain Transfer</h1>
            </div>
            <div
              className="col-sm-3 wow fadeInDown"
              style={{ visibility: 'visible', animationName: 'fadeInDown' }}
            >
              <div className="bread-crumb">
              <Link to="/">  <a >Home</a> / <a  className="active">Domain Transfer</a></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="domain-search parallax-section">
        <div className="custom-overlay">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12  justify-content-md-center custom-center">
              <div className="domain-title   text-center  custom-domain-title">
                <h3>Switch Your Domain to Us</h3>
                <p className="mb-2">Easier and Cost-Effective Than Renewal!</p>
              </div>
              <div className="bg_parallax custom-parallax">
                <div className="row  justify-content-md-center">
                  <div className="col-lg-8 text-white">
                    <form  className="search-whois">
                      <div id="domain">
                        <div className="l1">
                          <input type="text" name="domain" className="input custom-input" />
                          <input name="Submit" type="submit" value="Search" className="input search-button" />
                        </div>
                        <div className="r">
                          <p className="custom-text-center">(e.g yourcompany.com)</p>
                        </div>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-standard">
                <div className="container">
                    <div className="row text-center">
                    <div className="col-lg-12 wow fadeInDown heading-section animated custom-heading-section">
                            <h2 className="text-bold mb-3">Affordable Domain Registration</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 custom-col-md-2">
                            <div>
                                <span className="c-text-green font24"><strong>.COM </strong> </span>
                                <span className="price"><s>INR 875</s></span>
                                <br /><span className="font16 l-margine40">INR 749</span>
                            </div>
                        </div>
                        <div className="col-md-2 custom-col-md-2">
                            <div>
                                <span className="c-text-green font24"><strong>.NET </strong> </span>
                                <span className="price">INR 1020</span>
                            </div>
                        </div>
                        <div className="col-md-2 custom-col-md-2">
                            <div>
                                <span className="c-text-green font24"><strong>.INFO </strong> </span>
                                <span className="price"><s>INR 1110</s></span>
                                <br /><span className="font16 l-margine40">INR 350</span>
                            </div>
                        </div>
                        <div className="col-md-2 custom-col-md-2">
                            <div>
                                <span className="c-text-green font24"><strong>.IN </strong> </span>
                                <span className="price">INR 590</span>
                            </div>
                        </div>
                        <div className="col-md-2 custom-col-md-2">
                            <div>
                                <span className="c-text-green font24"><strong>.XYZ </strong> </span>
                                <span className="price"><s>INR 790</s></span>
                                <br /><span className="font16 l-margine40">INR 90</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="domain-features">
        <div className="container">
          <div className="row mb-4">
            <div className="col-sm-12 wow fadeInDown text-center heading-section" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <h2 className="text-bold">Key Domain Features</h2>
              <p className="text-muted custom-para">Get More with Your Domain Purchase</p>
            </div>
          </div>
         
         
          <div className="row custom-row">
            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
                <i className="fa fa-unlock-alt icon-over"></i>
                <div className="contents">
                  <h6>Keep Your Domain Details Private</h6>
                  <p>Domain privacy ensures complete confidentiality of owner information.</p>
                  <br />
                </div>
                </div>
                 {/* </div>  */}
      

             
            
            
           
            
          
          
          
            {/* <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
                <i className="fa fa-unlock-alt icon-over"></i>
                <div className="contents">
                  <h6>Domain Privacy Protection two</h6>
                  <p>Privacy protection enables complete privacy of domain owner information.</p>
                  <br />
                </div>
              </div>
            </div> */}
           
            
          
          </div>
        {/* <div className="feature-container">  */}
          
            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
              <i class="fa fa-share-square icon-over"></i>
                <div className="contents">
                  <h6>Emails Forwarding at No Cost</h6>
                  <p>Effortlessly redirect your emails to existing accounts with our complimentary email forwarding service.</p>
                  <br />
                </div>
              </div>
            </div>
            
         
            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
                <i class="fa fa-cog icon-over"></i>
                <div className="contents">
                  <h6>Effortless DNS Control</h6>
                  <p>Experience robust DNS management for free, with control over your records on our highly redundant DNS infrastructure.</p>
                  <br />
                </div>
              </div>
            </div>
            
          
            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
              <i class="fa fa-envelope icon-over"></i>
                <div className="contents">
                  <h6>Budget-Friendly Domains</h6>
                  <p>Secure your online presence with unbeatable, budget-friendly domain prices.</p>
                  <br />
                </div>
              </div>
            </div>
            
         
            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
                
                <i class="fa fa-random icon-over"></i>
                <div className="contents">
                  <h6>Prevent Unauthorized Access</h6>
                  <p>Prevent unauthorized access and safeguard your domain with our free Domain Theft Protection.</p>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 mb-4 wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <div className="feature">
              <i class="fa fa-key icon-over"></i>
                <div className="contents">
                  <h6>Authorization Code for Transfer</h6>
                  <p>Generate your domain transfer code anytime for seamless transfers to any other registrar, and we won't inquire about the reasons.</p>
                  <br />
                </div>
              </div>
            </div>
            
            
            </div>
           
            </div>
      </section>

    </div>
    </div>
  );
};


