import React, { useState } from "react";
import "../VPS/Common1.css";

import { Card, List, Button } from "antd";
import { Collapse } from "antd";
import { faBlackboard } from "@fortawesome/free-solid-svg-icons";

const { Panel } = Collapse;

export const Contact = () => {
  return (
    <div>
      {/* <div className="bg-heading">
        <div className="container bg">
          <div className="row c-white">
            <div
              className="col-sm-9 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <h1 className="breadcrumb-heading" href="/">
                About Algo Optimize
              </h1>
            </div>
            <div
              className="col-sm-3 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <div className="bread-crumb">
                <a href="/">Home</a> /{" "}
                <a
                  href="leh"
                  className="active"
                  title="Linux Virtual Private Server"
                >
                  Linux Enterprise Hosting
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section id="contact">
      <div className="container wow fadeInDown c-white" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
        <div className="row">
          <div className="col-lg-12 text-center">
            <h1 className="section-heading mb-3 font24">GET IN TOUCH</h1>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <form name="sentMessage" id="contactForm" method="#">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="name">Full name:</label>
                    <input type="text" className="form-control" placeholder="Your Name" id="name" name="name" required="" />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" className="form-control" placeholder="Your Email" id="email" name="email" required="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="subject">Subject:</label>
                    <input type="text" className="form-control" placeholder="Subject" id="subject" name="subject" required="" />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="phone">Contact Number:</label>
                    <input type="text" className="form-control" placeholder="Contact Number" id="phone" name="phone" required="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="form-group">
                    <label htmlFor="message">Your Message:</label>
                    <textarea className="form-control" placeholder="Your Message" name="message" id="message" required=""></textarea>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3">
                  {/* <div className="form-group"> */}
                    {/* <div id="success"></div> */}
                    <button type="submit" className="btn btn-warning" >Submit</button>
                  {/* </div> */}
                </div>
              </div>
            </form>
          </div>
          <div itemscope="" itemtype="" className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div itemprop="address" itemscope="" itemtype="">
              <div className="contact-address">
                <h2 itemprop="name" className="no-margin"><b>AlgoOptimize</b></h2>
              </div>
              <p className="contact-address mt-1" itemprop="streetAddress">office No:106</p>
              <p className="contact-address" itemprop="addressLocality">Vardhman palm Rose,building punawale</p>
              <p className="contact-address" itemprop="addressRegion">punawale, Pune</p>
              <p className="contact-address" itemprop="postalCode"> IN - 411033</p>
              <p className="contact-address" itemprop="telephone"> Mobile- +917499679376</p>
              {/* <p className="contact-address" itemprop="email"> E-mail : contact@mediastroke.com</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>


   
    

     
    </div>
  );
};
