import React from "react";
  import '../VPS/Common1.css';

  import { Card, List, Button } from 'antd';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Windowssharedhosting = () =>{

  
  const selectPayment = (duration) => {
    // Implement your selectPayment logic here
    // This function should handle tab switching
  };

 
 


    return(
    <div>
        <div className="bg-heading">
      <div className="container bg">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <h1 className="breadcrumb-heading">Windows Shared Hosting</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="bread-crumb">
              <a href="/">Home</a> / <a href="virtual-private-server" className="active" title="Linux Virtual Private Server">Windows Shared Hosting</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="hosting-block1 parallax-section">
      <div className="container hb1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <img src="/img/hostingblock1.png" alt="Cheap VPS hosting in India" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
              <div className="domain-title">
                <h2>Windows Shared Hosting</h2>
              </div>
              <p className="font18">A complete hosting solution for your business or personal website</p>
              <ul className="h-list">
                <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Free Migration to MediaStroke
                </li>
                <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Windows 2019, Plesk, ASP.NET, MSSQL, MySQL, PHP, IIS
                </li>
                <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Windows hosting with individual Plesk Panel

                </li>
                <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i>  Backup of Website, Database, and Email
                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Free Add-ons Softwares like WordPress, osCommerce
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    <div className="hosting-block2  parallax-section hosting">
        <div className="container hb2">
           <div className="row text-center">
             <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
               <h2 className="section-heading">Windows Shared Hosting</h2>
                <p className="section-subheading text-muted mb-4"> Fast, Secure, Reliable Hosting that helps to increase your Business. </p>
             </div>
               

             <div className="col-md-4 col-sm-6 col-xs-6 mb-4 linux-s">
      <div className="host-plan wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title1">WINDOWS STANDARD</h4>

        <div className="tab-content p-0">
          <div className="tab-pane container active" id="triennially_1">
            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="triennially1">$1.36/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="biennially_1">
            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="biennially1">$1.40/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="annually_1">
            <span className="host-price" id="annually1">$1.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
              <a className="nav-link active" onClick={() => selectPayment('triennially')} data-toggle="pill" href="#triennially_1">36M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" onClick={() => selectPayment('biennially')} data-toggle="pill" href="#biennially_1">24M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" onClick={() => selectPayment('annually')} data-toggle="pill" href="#annually_1">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">1 Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="sls_btn" className="host-btn">
          <a className="btn blackbox active" href="https://mediastroke.com/store/index.php?rp=/store/linux-shared-hosting/linux-standard&billingcycle=triennially">GET STARTED</a>
        </div>
      </div>
             </div>
            
              
             <div className="col-md-4 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title2">WINDOWS BUSINESS</h4>

        <div className="tab-content p-0">
          <div className="tab-pane container active" id="triennially_2">
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially2">$4.18/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="biennially_2">
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially2">$4.30/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="annually_2">
            <span className="host-price" id="annually2">$4.60/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
              <a className="nav-link active" data-toggle="pill" href="#triennially_2">36M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" data-toggle="pill" href="#biennially_2">24M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" data-toggle="pill" href="#annually_2">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">10 Websites</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slb_btn" className="host-btn">
          <a className="btn blackbox active" href="">GET STARTED</a>
        </div>
      </div>
    </div>



    <div className="col-md-4 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">WINDOWS PRO</h4>

        <div className="tab-content p-0">
          <div className="tab-pane container active" id="triennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially3">$5.00/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="biennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially3">$5.14/mo</span><br />
          </div>
          <div className="tab-pane container fade" id="annually_3">
            <span className="host-price" id="annually3">$5.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
              <a className="nav-link active" data-toggle="pill" href="#triennially_3">36M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" data-toggle="pill" href="#biennially_3">24M</a>
            </li>
            <li className="nav-item monthtab">
              <a className="nav-link" data-toggle="pill" href="#annually_3">12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">Unlimited Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="">GET STARTED</a>
        </div>
      </div>
    </div>

        
            
           

              
                        
           </div>
        </div>

     </div>






               <div className="live-demo">
                   <div className="container ld">
                   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="domain-title text-center">
        <h2>Windows Shared Hosting with Plesk</h2>
      </div>
      <div className="row lshp">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <img src="/img/plesk-logo.png"  className="cpanel-img" alt="Linux cPanel Hosting" />
        </div>
        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 wow fadeInDown mb-3 textp" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <p>Plesk is the best web hosting platform for windows hosting, Plesk automates and grow applications, websites, and hosting businesses. It offers easy to use control panel to manage files, databases, mail, application pool, backup's, scheduling, one-click application installation, and more.</p>
          <p>Plesk is a popular and high featured hosting control panel, we installed few third party modules in plesk panel like mod security, anti spammer, anti-malware, virus scanner, we application firewalls

F</p>
        </div>
      </div>
    </div>


                       
                   </div>
               </div>


               <div className="hosting-features mb-3">

                   <div className="container hf">

                      <div className="row justify-content-center mb-5">
                          <div className="col-md-8 text-center heading-section">
                             <h2 className="">
                              <span>Features of </span>
                              Windows Shared Hosting
                             </h2>
                          </div>
                       </div>


                 <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa fa-code fa-4x c-text-green mb-2"></i>
                                       <h4>PROGRAMMING & DATABASES</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                   Linux server hosting supports CGI, PHP, Python as programming languages, MySql database, 
                                   also allow features cron-jobs (Task scheduling), index manager, SSL, etc.
                                    
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-briefcase fa-4x c-text-green mb-2"></i>
                                       <h4>CMS</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Word press, Joomla softaculous software bunch provides free open source CMS software's 
                                     like Word press, Joomla, OS-Commerce, PrestaShop etc. in three step installation.
                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-arrow-up  fa-4x c-text-green mb-2"></i>
                                       <h4>EASY UPGRADE</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     All Linux hosting plans are easy to upgrade or downgrade with the pro-rata charges, 
                                     the customer can upgrade or downgrade the package from user login.
                                    
                                   </div>

                               </div>
                          </div>
                 </div>                       

                 <div className="rows">                    
                              <div class="columns">
                                   <div class="cards">
                                     <div className="features mb-5">
                                          <i className="fa fa-envelope fa-4x c-text-green mb-2"></i>
                                          <h4>E-MAIL FEATURES</h4>
                                     </div>

                                       <div className="pt-3 p-txt">
                                       The mail service on Linux server is efficiently working for business emails. The delivery failure and 
                                       spamming issues will never found in Linux hosting.                                       </div>

                               </div>
                              </div>


                               <div class="columns">
                                  <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-cogs fa-4x c-text-green mb-2"></i>
                                       <h4>DNS MANAGEMENT</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     The Linux servers allow managing DNS records from cPanel so there is no need for additional paid DNS management service. The SPF, DKIM, and MX are also available in cPanel.  </div>

                                  </div>
                               </div>

                               
                               <div class="columns">
                                 <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-eye fa-4x c-text-green mb-2"></i>
                                       <h4>MONITORING</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Monitoring features are available and you will be notified suddenly, We monitor all your services, CPU utilization, memory consumption, and security.
                                   </div>
                               </div>
                               </div>
                   </div>


                   </div>

               </div>


              
                        

                        
   

    </div>
    )
}