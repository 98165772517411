
import React, { useState, useEffect } from "react";
import './header.css';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
export const Header = () => {


  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);



  const handleNavbarToggle = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  const handleNavbarClose = () => {
    setIsNavbarExpanded(false);
  };

  useEffect(() => {

    return () => setIsNavbarExpanded(false);
  }, []);





  return (

    <div className="container-fluid">
      <div className="top-bar">
        <div id="navbar" class="header">
          <div class="container header-container">
            <div class="row row-head">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                <div class="full">
                  <div class="center-desk">
                    <div class="logo">
                      <Link to="/">
                        <span><img src="/img/logo3.png" alt="#" className="algo" /></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                <nav class="navigation navbar navbar-expand-md navbar-dark ">
                  <button class="navbar-toggler" type="button" onClick={handleNavbarToggle} aria-controls="navbarNav" aria-expanded={isNavbarExpanded} aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div className={`collapse navbar-collapse ${isNavbarExpanded ? "show" : ""}`} id="navbarNav">

                    <ul class="navbar-nav">
                      <li class="nav-item dropdown">
                        <Link to="/">  <a class="nav-link dropdown-toggle " id="navbarDropdown" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> VPS  </a></Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                          <Link to="/hs">  <a class="dropdown-item" onClick={handleNavbarClose}  >High Speed SSD VSD</a></Link>
                          <Link to="/lvps">    <a class="dropdown-item" onClick={handleNavbarClose} >Linux Virtual Private Server</a></Link>
                          {/* <Link to="/wvps">    <a class="dropdown-item"  onClick={handleNavbarClose} >Windows Virtual Private Server</a></Link> */}
                          <Link to="/livps">    <a class="dropdown-item" onClick={handleNavbarClose} >Linux India VPS</a></Link>
                          {/* <Link to="/wivps">    <a class="dropdown-item" onClick={handleNavbarClose}  >Windows India VPS</a></Link> */}


                        </div>

                      </li>

                      <li class="nav-item dropdown">
                        <Link to="/">      <a class="nav-link  dropdown-toggle" id="navbarDropdown" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Shared Hosting</a></Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                          <Link to="/lsh"><a class="dropdown-item" onClick={handleNavbarClose}  >Linux Shared Hosting</a></Link>
                          <Link to="/leh"><a class="dropdown-item" onClick={handleNavbarClose}   >Linux Enterprise Hosting</a></Link>
                          {/* <Link to="/wsh">  <a class="dropdown-item"  onClick={handleNavbarClose}    >Windows Shared Hosting</a></Link> */}


                        </div>

                      </li>

                      <li class="nav-item dropdown">
                        <Link to="/">     <a class="nav-link  dropdown-toggle" id="navbarDropdown" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Domain</a></Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                          <Link to="/reg"><a class="dropdown-item" onClick={handleNavbarClose}  >Register New Domain</a></Link>
                          <Link to="/td"><a class="dropdown-item" onClick={handleNavbarClose}   >Transfer Domain</a></Link>
                          <Link to="/dp">  <a class="dropdown-item" onClick={handleNavbarClose}  >Domain Pricing List</a></Link>


                        </div>

                      </li>

                      <li class="nav-item dropdown">
                        <Link to="/">      <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> SSL</a></Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                          <Link to="/ssl"><a class="dropdown-item" onClick={handleNavbarClose}  >SSl Certificate</a></Link>
                        </div>
                      </li>

                      <li class="nav-item dropdown">
                        <Link to="/">     <a class="nav-link  dropdown-toggle" id="navbarDropdown" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Company</a></Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                          <Link to="/about"><a class="dropdown-item" onClick={handleNavbarClose}  >About Us</a></Link>
                          <Link to="/contact"><a class="dropdown-item" onClick={handleNavbarClose}   >Contact</a></Link>
                        </div>
                      </li>
                    </ul>

                    {/* buttons add */}

                    <div className="sign-in-sign-up-buttons">
                    <Link to="/login"> 
                      <button className="button sign-up-button">Log In</button></Link>
                      {/* <button className="button sign-in-button">Sign In</button> */}

                    </div>
                  </div>
                </nav>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>




  );
}
