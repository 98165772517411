import React ,{useState} from "react";
  import '../VPS/Common1.css';

  import { Card, List, Button } from 'antd';
import { Collapse } from 'antd';
import { Link } from "react-router-dom";

const { Panel } = Collapse;

export const Linuxsharedhosting = () =>{

  // const [activeTab, setActiveTab] = useState('triennially');

  // const handleTabClick = (tab) => {

  //   setActiveTab(tab);
  // };
  const [activeTab1, setActiveTab1] = useState('triennially');
  const [activeTab2, setActiveTab2] = useState('triennially');
  const [activeTab3, setActiveTab3] = useState('triennially');

  const handleTabClick1 = (tab) => {
    setActiveTab1(tab);
  };

  const handleTabClick2 = (tab) => {
    setActiveTab2(tab);
  };

  const handleTabClick3 = (tab) => {
    setActiveTab3(tab);
  };

 
// const data = [
//     {
//       title: 'LINUX STANDARD',
       
//       content: [

//         {
//           price: 'Unlimited Storage Space',
//           space: 'Unlimited Bandwidth',
//           user: 'Unlimited E-mail Accounts',
//           support: '1 Website',
//           backup: 'Control Panel & FTP',
//           access: 'Unlimited Sub-Domains'
//         }
//       ],
     
//     },
//     {
//       title: 'LINUX BUSINESS',
      
//       content: [
//         {
//           price: 'Unlimited Storage Space',
//           space: 'Unlimited Bandwidth',
//           user: 'Unlimited E-mail Accounts',
//           support: '10 Website',
//           backup: 'Control Panel & FTP',
//           access: 'Unlimited Sub-Domains'
//         }
//       ],
   
//     },
//     {
//       title: 'LINUX PRO',
  
//       content: [
//         {
//           price: 'Unlimited Storage Space',
//           space: 'Unlimited Bandwidth',
//           user: 'Unlimited E-mail Accounts',
//           support: 'Unlimited Website',
//           backup: 'Control Panel & FTP',
//           access: 'Unlimited Sub-Domains'
//         }
//       ],
        
//     }
//   ];

    return(
    <div>
        <div className="bg-heading">
      <div className="container bg">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <h1 className="breadcrumb-heading">Linux Shared Hosting</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
            <div className="bread-crumb">
            <Link to="/">     <a >Home</a> / <a  className="active" title="Linux Virtual Private Server">Linux Shared Hosting</a></Link>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="hosting-block1 parallax-section">
      <div className="container hb1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
              <img src="/img/hostingblock1.png" alt="Cheap VPS hosting in India" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 c-white">
              <div className="domain-title">
                <h2>Your Website, Our Expertise</h2>
              </div>
              <p className="font18">Trust in our expertise to provide your website with a complete hosting solution.</p>
              <ul className="h-list">
                <li className="wow fadeInDown" data-wow-duration="600ms" data-wow-delay="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationDelay: '600ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Effortless Move to AlgoOptimize
                </li>
                <li className="wow fadeInDown" data-wow-duration="700ms" data-wow-delay="700ms" style={{ visibility: 'visible', animationDuration: '700ms', animationDelay: '700ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Instant AlgoOptimize Setup
                </li>
                <li className="wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Hosting: Your Personal Control Panel
                </li>
                <li className="wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i>  Safeguarding Your Website, Database, and Email
                </li>
                <li className="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
                  <i className="fa fa-chevron-circle-right c-text-green font18"></i> Additional Free Software: AlgoOptimize's Value-Added Selection
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="hosting-block2  parallax-section hosting">
        <div className="container hb2">
           <div className="row text-center">
             <div className="col-md-12 mb-2 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
               <h2 className="section-heading" href="/">AlgoOptimize: Your Linux Shared Hosting Solution</h2>
                <p className="section-subheading text-muted mb-4"> Perfect for All, from Startups to Corporations. Our Linux servers offer a powerful deployment environment, fostering extensive customizability and unmatched flexibility to enhance your customers' experience. Trust in our secure, scalable, up-to-date servers, meticulously monitored for an elevated user experience.
</p>
             </div>
               

             <div className="col-md-4 col-sm-6 col-xs-6 mb-4 linux-s">
      <div className="host-plan wow fadeInDown" data-wow-duration="800ms" data-wow-delay="800ms" style={{ visibility: 'visible', animationDuration: '800ms', animationDelay: '800ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title1">Linux Standard</h4>

        <div className="tab-content p-0">
          
          <div className={`tab-pane container ${activeTab1 === 'triennially' ? 'active' : 'fade'}`} id="triennially_1">
            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="triennially1">$1.36/mo</span><br />
          </div>
       
          <div className={`tab-pane container ${activeTab1 === 'biennially' ? 'active' : 'fade'}`} id="biennially_1">

            <span className="currency">$</span>
            <span className="price1"><del>1.50</del></span>
            /<span className="unit">mo<br /></span>
            <span className="host-price" id="biennially1">$1.40/mo</span><br />
          </div>
          
          <div className={`tab-pane container ${activeTab1 === 'annually' ? 'active' : 'fade'}`} id="annually_1">

            <span className="host-price" id="annually1">$1.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
              <a className={`nav-link ${activeTab1 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('triennially')}
                                  href="#triennially_1">
                36M</a>
              
                                  
                                       
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab1 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('biennially')}
                                  href="#biennially_1">
              
                24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab1 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick1('annually')}
                                  href="#annually_1">

            
                12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">1 Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="sls_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
             </div>
            
              
             <div className="col-md-4 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="900ms" data-wow-delay="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationDelay: '900ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title2">Linux Business</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab2 === 'triennially' ? 'active' : 'fade'}`} id="triennially_2">
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially2">$4.18/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab2 === 'biennially' ? 'active' : 'fade'}`} id="biennially_2">
            <span className="currency">$</span>
            <span className="price2"><del>4.60</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially2">$4.30/mo</span><br />
          </div>
          
          <div className={`tab-pane container ${activeTab2 === 'annually' ? 'active' : 'fade'}`} id="annually_2">

            <span className="host-price" id="annually2">$4.60/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('triennially')}
                                  href="#triennially_2">
                36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('biennially')}
                                  href="#biennially_2">
                24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab2 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick2('annually')}
                                  href="#annually_2">
                12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">10 Websites</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slb_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
              </div>



    <div className="col-md-4 col-sm-6 col-xs-6 mb-1">
      <div className="host-plan wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="1000ms" style={{ visibility: 'visible', animationDuration: '1000ms', animationDelay: '1000ms', animationName: 'fadeInDown' }}>
        <h4 className="name" id="title3">Linux Pro</h4>

        <div className="tab-content p-0">
        <div className={`tab-pane container ${activeTab3 === 'triennially' ? 'active' : 'fade'}`} id="triennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="triennially3">$5.00/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab3 === 'biennially' ? 'active' : 'fade'}`} id="biennially_3">
            <span className="currency">$</span>
            <span className="price3"><del>5.50</del></span>/
            <span className="unit">mo<br /></span>
            <span className="host-price" id="biennially3">$5.14/mo</span><br />
          </div>
          <div className={`tab-pane container ${activeTab3 === 'annually' ? 'active' : 'fade'}`} id="annually_3">

            <span className="host-price" id="annually3">$5.50/mo</span><br />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills">
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'triennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('triennially')}
                                  href="#triennially_3">
                36M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'biennially' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('biennially')}
                                  href="#biennially_3">
                24M</a>
            </li>
            <li className="nav-item monthtab">
            <a className={`nav-link ${activeTab3 === 'annually' ? 'active' : ''}`}
                                  onClick={() => handleTabClick3('annually')}
                                  href="#annually_3">
                12M</a>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="plan-list">
          <li className="light">Unlimited Storage Space</li>
          <li className="dark">Unlimited Bandwidth</li>
          <li className="light">Unlimited E-mail Accounts</li>
          <li className="dark">Unlimited Website</li>
          <li className="light">Control Panel & FTP</li>
          <li className="dark">Unlimited Sub-Domains</li>
          <li className="light"><i className="fa fa-lock c-text-green fa-2x"></i> Free SSL Certificate</li>
        </ul>
        <div id="slp_btn" className="host-btn">
          <a className="btn blackbox active" href="#">GET STARTED</a>
        </div>
      </div>
    </div>

        
            
           

              
                        
           </div>
        </div>

     </div>

  

               <div className="live-demo">
                   <div className="container ld">
                   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="domain-title text-center">
        <h2>AlgoOptimize's Linux Shared Hosting with cPanel: The Ultimate Hosting Experience</h2>
      </div>
      <div className="row lshp">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <img src="/img/cpanel-logo.png"  className="cpanel-img" alt="Linux cPanel Hosting" />
        </div>
        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 wow fadeInDown mb-3 textp" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
          <p>cPanel is a widely used hosting control panel, known for its user-friendly interface and a comprehensive suite of features including File Manager, PHP Selector, SSL Certificate installations, MySQL Database management via PhpMyAdmin, Email account management, Crontab for scheduling tasks, Index Manager for directory control, Backup Manager for data protection, and streamlined CMS installation and management for platforms like WordPress, Joomla, Drupal, OpenCart, Magento, and more. Additionally, we've bolstered cPanel's capabilities by integrating third-party modules such as mod security, anti-spam, anti-malware, virus scanning, and firewalls to enhance security and performance within the control panel.
</p>
          
        </div>
      </div>
    </div>


                       
                   </div>
               </div>


               <div className="hosting-features mb-3">

                   <div className="container hf">

                      <div className="row justify-content-center mb-5">
                          <div className="col-md-8 text-center heading-section">
                             <h2 className="">
                              <span>Key Features of 
 </span> Linux Shared Hosting
                             
                             </h2>
                          </div>
                       </div>


                 <div class="rows">
                         <div class="columns">
                              <div class="cards">
                                   <div className="features mb-5">
                                       <i className="fa fa-code fa-4x c-text-green mb-2"></i>
                                       <h4>Programming & Database Capabilities
</h4>
                                    </div>
                                   <div className="pt-3 p-txt">
                                   Linux server hosting offers CGI, PHP, Python for programming, and MySQL databases. It includes cron-jobs, an index manager, SSL, FTP, SPF, and DKIM for added features and security.
                                    
                                   </div>
                              </div>
                         </div>

                         <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-briefcase fa-4x c-text-green mb-2"></i>
                                       <h4>CMS Installation Simplified</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Softaculous offers a three-step installation for free open-source CMS software like WordPress, Joomla, OS-Commerce, and PrestaShop, making website creation quick and easy.

                                    
                                   </div>

                               </div>
                         </div>

                          <div class="columns">
                               <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-arrow-up  fa-4x c-text-green mb-2"></i>
                                       <h4>Seamless Plan Adjustments</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Our Linux hosting plans support hassle-free upgrades or downgrades with pro-rata charges. Customers can conveniently adjust their package through their user login.

                                    
                                   </div>

                               </div>
                          </div>
                 </div>                       

                 <div className="rows">                    
                              <div class="columns">
                                   <div class="cards">
                                     <div className="features mb-5">
                                          <i className="fa fa-envelope fa-4x c-text-green mb-2"></i>
                                          <h4>Reliable Email Service</h4>
                                     </div>

                                       <div className="pt-3 p-txt">
                                       Our Linux server ensures efficient business email functionality. Experience reliable email delivery without issues such as delivery failures or spamming commonly associated with Linux hosting.                                    </div>

                               </div>
                              </div>


                               <div class="columns">
                                  <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-cogs fa-4x c-text-green mb-2"></i>
                                       <h4>Simplified DNS Control</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Linux servers, through cPanel, offer integrated DNS management, eliminating the need for extra costs. You can also enhance email and domain security with SPF, DKIM, and MX record management. </div>

                                  </div>
                               </div>

                               
                               <div class="columns">
                                 <div class="cards">
                                     <div className="features mb-5">
                                     <i className="fa fa-eye fa-4x c-text-green mb-2"></i>
                                       <h4>Proactive Monitoring</h4>
                                     </div>

                                     <div className="pt-3 p-txt">
                                     Our services include real-time monitoring, providing instant notifications for CPU usage, memory consumption, security, and all your vital services.

                                   </div>
                               </div>
                               </div>
                   </div>


                   </div>

               </div>


               <div className="live-demoo c-white">
                  <div className="container ld">
                             <div className="col-sm-12 text-center mb-4">
                                     <div className="domain-title">
                                          <h2>Linux Shared Hosting: Advanced Tools and Options</h2><br />
                                     </div>
                              </div>


                              <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-check-square fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Rapid Deployment and Easy Control</h4>
                                               <p>Harness the power of cPanel and Plesk for rapid setup, effortless CMS installations (e.g., WordPress, Joomla, Magento), and convenient centralized management through a single, intuitive interface.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-tasks fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Hosting Provider Solutions</h4>
                                               <p>Transform into a hosting provider with cPanel/WHM and Plesk panels. Enjoy centralized control, plan customization, and the ability to serve customers and resellers. Amplify your services with plugins, advanced security, and timely alerts for a profitable hosting venture.
                                                
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-fast-forward fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>No-Limits Speed and Data</h4>
                                               <p>Experience unlimited bandwidth with a minimum speed of 100Mbps on all plans, with no additional charges for extra usage. For unparalleled speed, inquire about dedicated 1Gbps lines.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-cogs fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>End-to-End Managed Services</h4>
                                               <p>Our fully managed solutions are custom-fit for your applications. We'll recommend the right plan, set it up, ensure seamless deployment, and take care of snapshots, backups, and monitoring. Additionally, we implement firewall rules and bolster OS security.
                                                </p>
                                     </div>
                               </div>


                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-graduation-cap fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Managed Hosting Excellence</h4>
                                      <p> Rely on our fully managed servers and 24/7 dedicated support for your application of choice. We create a secure, high-performance environment with dedicated IPs, SSL, firewalls, automated backups, performance enhancements, file permissions, snapshots, and continuous monitoring.</p>
                                     </div>
                               </div>



                  </div>
               </div>
                        

                        
   

    </div>
    )
}