import React, { useState } from "react";
import "../VPS/Common1.css";

import { Card, List, Button } from "antd";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

export const About = () => {
  return (
    <div>
      <div className="bg-heading">
        <div className="container bg">
          <div className="row c-white">
            <div
              className="col-sm-9 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <h1 className="breadcrumb-heading">
                About Algo Optimize
              </h1>
            </div>
            <div
              className="col-sm-3 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <div className="bread-crumb">
              <Link to="/">        <a >Home</a> /{" "}
                <a
                  
                  className="active"
                  title="Linux Virtual Private Server"
                >
                  Linux Enterprise Hosting
                </a></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-6 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <p>
                At Finabits, we are a leading web presence provider, offering a
                comprehensive suite of services to help our clients establish,
                enhance, and accelerate their online businesses. Our range of
                products includes Domain Registration, Web Hosting Services
                (Shared Hosting, Reseller Hosting, Dedicated Server, Virtual
                Private Server (VPS) with Dedicated IP Addresses, and SSL
                Certificates), all at attractive price points. With our
                round-the-clock customer service, we ensure that our clients
                receive the support they need. We cater to businesses of all
                sizes, recognizing the importance of each customer's unique
                needs. In today's global business landscape, where IT plays an
                integral role, we offer cost-effective solutions and IT services
                to help our customers grow rapidly in the international market.
                Our approach combines technology expertise, domain knowledge,
                process focus, and a commitment to long-term customer
                relationships. At the heart of our operations is a dedicated
                team with extensive experience in their respective fields,
                ensuring smooth and efficient workflows. We excel in providing
                solutions for websites, emails, web applications, servers, and
                data security. With a strong emphasis on customer satisfaction,
                we are available 24/7 to promptly address any issues or
                concerns. We believe in going above and beyond for our
                customers, recognizing that they may forget what we say but will
                never forget how we made them feel. That's why we consistently
                strive to exceed expectations and provide exceptional service.
              </p>
            </div>
            <div
              className="col-sm-6 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <img
                src="\img\wisteriaa.jpg"
                style={{ border: "1px solid #9b9393", padding: "1px" }}
                alt="MediaStroke Web Hosting Company"
              />
              <h2 align="center">
                A Decade of Web Hosting Mastery and Cutting-Edge Solutions
              </h2>
              <p>
                With a rich history of experience and continuous innovation
                within the web hosting sector, we've finely crafted our
                proficiency to deliver comprehensive solutions that precisely
                address the varied requirements of our valued clients. We
                acknowledge the perpetual evolution of the digital realm and are
                committed to maintaining our leadership position in pioneering
                technological breakthroughs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="live-demo text-white">
        <div className="container">
          <div className="col-sm-12">
            <div className="domain-title text-center">
              <h2>Achieving Web Hosting Excellence via Ongoing Enhancement</h2>
              <br />
            </div>
            <div className="row">
              <div
                className="col-sm-4 wow fadeInDown text-center"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h2>Trustpilot</h2>
                <br />
                <a
                  href="https://www.trustpilot.com/review/mediastroke.com"
                  target="_blank"
                >
                  <img
                    src="\img\stars-5.svg"
                    style={{ width: 300 }}
                    alt="MediaStroke Reviews on Trustpilot"
                  />
                  <h2>Trustpilot Rating 4.8/5</h2>
                </a>
              </div>
              <div
                className="col-sm-8 wow fadeInDown"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <div className="left b-margin3">
                  <p>
                    At Finabits, we value our customers' feedback, and that's
                    why we've seamlessly integrated the Trustpilot review system
                    into our customer portal. After every order placed with us,
                    Trustpilot automatically sends email invitations to our
                    customers, encouraging them to share their honest opinions.
                    This not only helps us collect genuine reviews but also
                    serves as an effective mechanism to identify and thwart any
                    fraudulent attempts, ensuring the authenticity of our
                    feedback. Your satisfaction drives our commitment to
                    excellence.
                  </p>
                  <p>
                    We're dedicated to showcasing Trustpilot reviews, a
                    testament to their reliability and credibility. Our
                    unwavering commitment to transparency and authenticity
                    shines through the Trustpilot reviews, which stand as a
                    beacon of trustworthiness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-md-center">
            <div
              className="col-sm-12 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <h2 className="font24">
                <b>Our Foundational Values </b>
              </h2>
              <p>
                At the inception of our company, we laid the groundwork by
                establishing three fundamental principles that have been the
                bedrock of our operations ever since.
              </p>
            </div>
            <div className="col-sm-4">
              <div className="features text-center">
                <i
                  className="fa fa-briefcase fa-4x c-text-green"
                  aria-hidden="true"
                ></i>
                <h4 className="text-center mt-3">Economical Solutions</h4>
                <div className="des">
                  We've Crafted an Expansive Product Lineup with Cost-Efficiency
                  and Premium Features.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="features text-center">
                <i
                  className="fa fa-life-ring fa-4x c-text-green"
                  aria-hidden="true"
                ></i>
                <h4 className="text-center mt-3">Swift Customer Service</h4>
                <div className="des">
                  Initiating 24/7 Customer Support was a pivotal step towards
                  enhancing our service quality.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="features text-center">
                <i
                  className="fa fa-graduation-cap fa-4x c-text-green"
                  aria-hidden="true"
                ></i>
                <h4 className="text-center mt-3">No-Cost Advisory</h4>
                <div className="des">
                  We extend complimentary consultations to all our customers,
                  offering expert guidance to enhance their website.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 wow fadeInDown mt-5"
            style={{ visibility: "visible", animationName: "fadeInDown" }}
          >
            <h2 className="font24">
              <b>Our Visionary Outlook </b>
            </h2>
          </div>
          <div
            className="col-sm-12 wow fadeInDown"
            style={{ visibility: "visible", animationName: "fadeInDown" }}
          >
            <p>
              We offer a comprehensive platform that empowers designers,
              developers, and system administrators worldwide to meet their
              diverse needs within a single, all-encompassing solution. Our
              commitment to delivering top-notch services at cost-effective
              rates spans the entire spectrum, starting from Domain Name
              registration, extending through Web Hosting, and culminating in
              robust Servers with scalable, secure, and reliable infrastructure.
            </p>
          </div>
        </div>
      </section>

      {/* <div className="live-demoo c-white">
                  <div className="container ld">
                             <div className="col-sm-12 text-center mb-4">
                                     <div className="domain-title">
                                          <h2>Advanced Features of Linux Enterprise Hosting</h2><br />
                                     </div>
                              </div>


                              <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-check-square fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Easy to use control panels</h4>
                                               <p>Save your valuable time using cPanel and Plesk, it provides a ready-to-code environment,
                                                   1-click installation and upgrade of CMS like WordPress, Joomla, Magento, etc.
                                                    Do easy administration using cPanel or Plesk from a single window.
                                                </p>
                                     </div>
                               </div>

                           

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-fast-forward fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Unlimited Traffic with high speed</h4>
                                               <p>All plans are available with unlimited bandwidth and minimum 100Mbps speed, there is no more cost for extra usage. Dedicated lines with 1Gbps are available for ultra-high speed.
                                                </p>
                                     </div>
                               </div>

                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-cogs fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Fully Managed solutions</h4>
                                               <p>We provide fully managed solutions as per the applications those you require. Will suggest the best suitable plan, set up for you, make it deployable and take care of it in terms of snapshots, backups, and monitoring. We set firewalls rules and os hardening.
                                                </p>
                                     </div>
                               </div>


                               <div className="row_advancedf mb-4">
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                                            <i className="fa fa-graduation-cap fa-5x c-text-green"></i>
                                    </div>
                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                       <h4>Go with our consultation</h4>
                                      <p> Fully Managed servers with 24/7 dedicated support on your choice of application, we make full secure, safe and high-performance bunch using our checklist which includes dedicated IP per domain, SSL security, firewall setup, auto backup, increase performance by clearing dump files and killing processes, file and directory permissions, snapshots and monitoring.</p>
                                     </div>
                               </div>



                  </div>
               </div> */}
    </div>
  );
};
