import React from "react";
import { Link } from "react-router-dom";
// import "../Dpricing.css";

export const Domainpricing = () => {
  return (
    <div className="bg-heading">
      <div className="container">
        <div className="row c-white">
          <div className="col-sm-9 wow fadeInDown">
            <h1 className="section-heading" >Domain Pricing</h1>
          </div>
          <div className="col-sm-3 wow fadeInDown">
            <div className="bread-crumb">
            <Link to="/">  <a >Home</a> /{" "}
              <a  className="active">
                Domain Pricing
              </a>
              </Link>
            </div>
          </div>
        </div>
        </div>
          {/* New Box  */}
      <div className="new-section-box">
         <div className="new-section-heading">
           <h4>Renewals</h4>
         </div>
         <div className="new-section-content">
          
         </div>
         <table className="table">
           <thead>
             <tr>
               <th>Domins</th>
               <th>1 Year</th>
               <th>2 Year</th>
               <th>3 Year</th>
               <th>5 Year</th>
               <th>10 Year</th>
             

              
              
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>.com</td>
               <td>$11.41</td>
               <td>$22.82</td>
               <td>$34.23</td>
               <td>$57.05</td>
               <td>-</td>
              
              
             </tr>

             <tr>
             <td>.net</td>
              <td>$14.29</td>
               <td>$28.58</td>
               <td>$42.88</td>
               <td>$71.46</td>
               <td>-</td>
              
                
                </tr>

                <tr>
                    <td>.org</td>
                <td>$14.53</td>
               <td>$29.06</td>
               <td>$43.60</td>
               <td>$72.66</td>
               <td>-</td>
              
              
                </tr>
       
                <tr>
                <td>.in</td>
                <td>$8.58</td>
               <td>$17.15</td>
               <td>$25.73</td>
               <td>$42.88</td>
               <td>-</td>
              
              
                </tr>

                <tr>
                <td>.co.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>-</td>
              
                </tr>

                <tr>
                <td>.org.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>-</td>
                </tr>
               

             <tr>
                
                <td>.net.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.info</td>
               
                <td>$19.71</td>
               <td>$39.40</td>
               <td>$59.10</td>
               <td>$98.49</td>
               <td>-</td>
             </tr>

             <tr>
                <td>pro</td>
                <td>$23.77</td>
               <td>$47.53</td>
               <td>$71.30</td>
               <td>$118.83</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.pw</td>
               <td>$19.46</td>
               <td>$38.91</td>
               <td>$58.37</td>
               <td>$97.28</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.biz</td>
               <td>$22.84</td>
               <td>$45.69</td>
               <td>$68.53</td>
               <td>$114.22</td>
               <td>-</td>
             </tr>
               

             <tr>
                <td>.biz</td>
               <td>$22.84</td>
               <td>$45.69</td>
               <td>$6853</td>
               <td>$114.22</td>
               <td></td>
             </tr>
                

             <tr>
                <td>.club</td>
               <td>$21.91</td>
               <td>$43.81</td>
               <td>$65.72</td>
               <td>$109.53</td>
               <td>-</td>
             </tr>
                   
             <tr>
                <td>.eu</td>
               <td>$22.84</td>
               <td>$45.69</td>
               <td>$6853</td>
               <td>$114.22</td>
               <td></td>
             </tr>

             <tr>
                <td>.me</td>
               <td>$39.13</td>
               <td>$78.26</td>
               <td>$117.39</td>
               <td>$195.64</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.mobi</td>
               <td>$34.88</td>
               <td>$69.75</td>
               <td>$104.63</td>
               <td>$174.39</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.site</td>
               <td>$37.04</td>
               <td>$74.08</td>
               <td>$111.12</td>
               <td>$185.19</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.top</td>
               <td>$10.95</td>
               <td>$21.91</td>
               <td>$32.86</td>
               <td>$54.77</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.online</td>
               <td>$43.81</td>
               <td>$87.62</td>
               <td>$131.44</td>
               <td>$219.06</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.blog</td>
               <td>$25.04</td>
               <td>$50.07</td>
               <td>$75.10</td>
               <td>$125.16</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.ca</td>
               <td>$11.72</td>
               <td>$-1.00</td>
               <td>$-1.00</td>
               <td>$-1.00</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.io</td>
               <td>$43.16</td>
               <td>$-1.00</td>
               <td>$-1.00</td>
               <td>$-1.00</td>
               <td>-</td>
             </tr>

             <tr>
                <td>.vip</td>
               <td>$13.93</td>
               <td>$27.86</td>
               <td>$41.78</td>
               <td>$69.63</td>
               <td>-</td>
             </tr>








             

              {/* Add more rows as needed  */}
           </tbody>
         </table>
       </div>


         {/* New Box */}
      <div className="new-section-box">
         <div className="new-section-heading">
           <h4>New Registration</h4>
         </div>
         <div className="new-section-content">
         
         </div>
         <table className="table">
           <thead>
             <tr>
               <th>Domins</th>
               <th>1 Year</th>
               <th>2 Year</th>
               <th>3 Year</th>
               <th>5 Year</th>
               <th>10 Year</th>
             

              
              
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>.com</td>
               <td>$11.41</td>
               <td>$22.82</td>
               <td>$34.23</td>
               <td>$57.05</td>
               <td>$57.05</td>
              
              
             </tr>

             <tr>
             <td>.net</td>
          
            
               <td>$14.29</td>
               <td>$28.58</td>
               <td>$42.88</td>
               <td>$71.46</td>
               <td>$42.88</td>
              
                
                </tr>

                <tr>
                    <td>.org</td>
                <td>$14.53</td>
               <td>$29.06</td>
               <td>$43.60</td>
               <td>$72.66</td>
               <td>$72.66</td>
              
              
                </tr>
       
                <tr>
                <td>.in</td>
                <td>$8.58</td>
               <td>$17.15</td>
               <td>$25.73</td>
               <td>$42.88</td>
               <td>$25.73</td>
              
              
                </tr>

                <tr>
                <td>.co.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>$34.95</td>
              
                </tr>

                <tr>
                <td>.org.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>$13.98</td>
                </tr>
               

             <tr>
                
                <td>.net.in</td>
                <td>$6.99</td>
               <td>$13.98</td>
               <td>$20.97</td>
               <td>$34.95</td>
               <td>$20.97</td>
             </tr>

             <tr>
                <td>.info</td>
               
                <td>$19.71</td>
               <td>$39.40</td>
               <td>$59.10</td>
               <td>$98.49</td>
               <td>$98.49</td>
             </tr>

             <tr>
                <td>pro</td>
                <td>$23.77</td>
               <td>$47.53</td>
               <td>$71.30</td>
               <td>$118.83</td>
               <td>$23.77</td>
             </tr>

             <tr>
                <td>.pw</td>
               <td>$19.46</td>
               <td>$38.91</td>
               <td>$58.37</td>
               <td>$97.28</td>
               <td>$58.37</td>
             </tr>

             <tr>
                <td>.biz</td>
               <td>$22.84</td>
               <td>$45.69</td>
               <td>$6853</td>
               <td>$114.22</td>
               <td>$45.69</td>
             </tr>

             <tr>
               <td>.com</td>
               <td>$11.41</td>
               <td>$22.82</td>
               <td>$34.23</td>
               <td>$57.05</td>
               <td>$57.05</td>
              
              
             </tr>
             

             <tr>
                <td>.pw</td>
               <td>$19.46</td>
               <td>$38.91</td>
               <td>$58.37</td>
               <td>$97.28</td>
               <td>$58.37</td>
             </tr>
            



             

             {/*  Add more rows as needed  */}
           </tbody>
         </table>
       </div>

       
    </div>
    
   
     
    
  );
};

      
