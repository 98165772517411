import React from "react";
import "./bestwebhosting.css";

export const Bestwebhosting =() =>{

    return(
        <div className="webhosting">

            <div className="container wh">

                 <div className="row justify-content-center">

                     <div className="col-lg-12 wow fadeInDown text-center heading-section">
                          <h2 className="">
                              <span>Leading Hosts of</span> 2022
                          </h2>
                          <p>Bringing Unprecedented Industry Advantages Under One Roof</p>
                    </div>
                 
                 




                 <div className="row row-search">
            <div className="column">
                <div className="card" >
                <img src="/img/pricing.png" className="web-img"></img>
                <h4> Exceptional Industry Pricing</h4>
                  
                    <p >Comprehensive Web Design & Development Solutions at Competitive Prices with Exclusive Promotions and Affiliate Programs</p>
                 </div>
            </div>
            <div className="column">
                <div className="card">
                <img src="/img/performance.png" className="web-img"></img>
                <h4>Top-notch Performance</h4>


                 
                 <p >Setting the Standard: Latest Processors, Ample Storage, Expandable Memory, High-Speed Networking, and Zero Downtime, All Fueled by Our Passionate Expertise.</p> 
                </div>
            </div>
            <div className="column">
                <div className="card">
                <img src="/img/help.png" className="web-img"></img>
                <h4>Constant Help</h4>
                    <p >We don't just make commitments; we deliver exceptional and quick support from our seasoned experts. You have the flexibility to select your preferred mode of communication, be it phone, chat, or support ticket.</p>
                </div>
            </div>
             </div>

                 {/* <div className="col-md-4">
                        <div className="feature-box-icon">
                          <div className="inner-bvh">
                             <i className="fa fa-suitcase circle"></i>
                              <div className="text">
                                <h4>Industry Best Prices</h4>
                                     Products to meet every Web Design &amp; Development need in Highly competitive Pricing &amp; excellent Promotions. Available with Affiliations and promos.
                              </div>
                          </div>
                        </div>
                  </div>

                  <div className="col-md-4">
                        <div className="feature-box-icon">
                          <div className="inner-bvh">
                             <i className="fa fa-rocket circle"></i>
                              <div className="text">
                                <h4>High Performance</h4>
                                Latest high computing processors, large storage, scalable memory, high network speed, and zero downtime is our superiority with passionating Expertise.
                              </div>
                          </div>
                        </div>
                  </div>


                  <div className="col-md-4">
                       <div className="feature-box-icon">
                          <div className="inner-bvh">
                          <i class="fa fa-life-ring circle"></i>
                          <div className="text">
                            <h4>24/7/365 Support</h4>
                                 Not just commitments we provide high-end and quick support from experts, you have to choose the way of communication(Phone, Chat, and Support Ticket)
                         </div>
                          </div>
                       </div>
                  </div> */}
     

                  
                    

                 </div>
             </div>

        </div>
    )
}
