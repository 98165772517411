import React from "react";
// import "../VPS/Common1.css";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const { Panel } = Collapse;
const planStyle = {
  visibility: "visible",
  animationName: "fadeInDown",
};

export const Sslcertificate = () => {
  return (
    <div>
      <div className="bg-heading">
        <div className="container bg">
          <div className="row c-white">
            <div
              className="col-sm-9 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <h1 className="breadcrumb-heading">SSL Certificate</h1>
            </div>
            <div
              className="col-sm-3 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <div className="bread-crumb">
              <Link to="/">    <a >Home</a> /{" "}
                <a
                  href="virtual-private-server"
                  className="active"
                  title="Linux Virtual Private Server"
                >
                  SSL Certificate
                </a></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hosting-block2  parallax-section">
        <div className="container hb2">
          <div className="row text-center">
            <div
              className="col-md-12 mb-2 wow fadeInDown"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              <h1 className="h1-headingg">
                Protect Your Customer's Personal Data with SSL Certificates
              </h1>
              <p className="section-sub text-mut mb-4">
                SSL Certificates play a crucial role in securing your online
                business and ensuring the safety of your customer's personal
                data. 

                <h1 className="hheading">
                  <span>Starts at just</span> Rs.871.00/year
                </h1>
              </p>
            </div>

            <section className="bg-standard stand">
              <div className="container">
                <div className="row">
                  <div
                    className="mb-1 col-md-6 col-sm-6 wow fadeInDown"
                    style={planStyle}
                  >
                    <div className="plan">
                      <h3 className="text-center">
                        <b>Positive SSL Certificate</b>
                      </h3>
                      <p className="plan-color text-center">
                        Secure your website with our affordable Positive SSL
                        Certificate, starting at just INR 871.00 per year.
                      </p>
                      <div className="plan-desc">
                        <ul>
                          
                          <li>
                            $10,000 Relying Party Warranty: Gain peace of mind
                            with our relying party warranty.
                          </li>
                        </ul>
                        
                      </div>
                      <p className="text-center">
                        <a className="btn btn-danger text-white" href="#">
                          Buy Now
                        </a>
                      </p>
                    </div>
                  </div>

                  <div
                    className="mb-1 col-md-6 col-sm-6 wow fadeInDown"
                    style={planStyle}
                  >
                    <div className="plan">
                      <h3 className="text-center">
                        <b>Positive SSL Wildcard Certificate</b>
                      </h3>
                      <p className="plan-color text-center">
                        Secure your domain and unlimited sub-domains with our
                        cost-effective Positive SSL Wildcard Certificate, priced
                        at just INR 9,643.00 per year.
                      </p>
                      <div className="plan-desc">
                        <ul>
                          
                          <li>
                            Unlimited Sub-domains: Secure all your sub-domains
                            under a single certificate.
                          </li>
                         

                         
                        </ul>
                      </div>
                      <p className="text-center">
                        <a className="btn btn-danger text-white" href="#">
                          Buy Now
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="hosting-features mb-3 ddd">
        <div className="container hf">
          <div className="row justify-content-center mb-5">
            <div className="col-md-8 text-center heading-section">
              <h2 className="cert">
                <span>Why Invest in an SSL Certificate?</span>
              </h2>
            </div>
          </div>

          <div class="rows">
            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                 
                  <img src="/img/lock-unlocked.png"></img>
                  <h4>Robust Security</h4>
                </div>
                <div className="pt-3 p-txt">
                  Up to 128 or 256-bit Encryption: We employ the highest level
                  of encryption standards to safeguard your website visitors'
                  sensitive data. 
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                 
                  <img src="/img/boosting.png"></img>
                  <h4>Boosting customer confidence </h4>
                </div>

                <div className="pt-3 p-txt">
                  Leading search engines, including Google, prioritize websites
                  secured with SSL certificates. 
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  <img src="/img/customer-journey.png"></img>
                  <h4>Enhance Your SEO Rankings </h4>
                </div>

                <div className="pt-3 p-txt">
                  SSL certificates protect sensitive data, including customer
                  information and payment details. 
                </div>
              </div>
            </div>
          </div>

          <div className="rows">
            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  
                  <img src="/img/shield.png"></img>
                  <h4>The Importance of a Trust Seal</h4>
                </div>

                <div className="pt-3 p-txt">
                  The Comodo Secure Seal serves as a continuous reminder to your
                  customers that your website is diligently protected. 
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="cards">
                <div className="features mb-5">
                  
                  <img src="/img/min.png"></img>
                  
                  
                  <h4>Our 30-Day Money-Back Guarantee</h4>
                </div>

                <div className="pt-3 p-txt">
                  We prioritize your satisfaction above all else. If, within the
                  first 30 days of your SSL certificate purchase.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq">
        <div className="container fq"></div>
      </div>
    </div>
  );
};
